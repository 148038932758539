import React, {useContext, useRef, useEffect}  from 'react';
import { I18NContext } from "./Contexts";
import { FieldsBox } from "./FieldsBox";

const getErrorMesssage = (lang, key, v)=>{
  return [...key.split(","), v].reduce((sf, x)=>{
    const joiner = (sf==="" || lang[x]===undefined)?"":" ";
    return sf+joiner+(lang[x]??x);
  }, "");
}

export const ErrorSection = ({errors})=>{
  const lang = useContext(I18NContext);
  const focusRef = useRef();
  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, [errors]);
  return <FieldsBox >
    <div className="row">
      <div className="large-12 columns invalid" tabIndex="0" ref={focusRef}>
        {<legend>{lang.errorTitle}</legend>}
      </div>
    </div>
    {Object.entries(errors).map(([k,v], idx)=><div key={k}  tabIndex="0"><span className="invalid">{getErrorMesssage(lang, k, v)}</span></div>)}
  </FieldsBox>;
}