import React, {useContext, useEffect} from 'react';
import {I18NContext, ValidateContext} from "./Contexts";

const validate = (value, required, vFuncs)=>{
  if(!value && required) return "eRequired";
  else if(!value && !required) return null;
  else{
    for (let f of vFuncs){
      const e = f(value)
      if(e) return e;
    }
  }
}

export const Validator = ({value, required=true, vFuncs=[], id})=>{
  const lang=useContext(I18NContext);
  const {show, setVError}=useContext(ValidateContext);
  const error = validate(value, required, vFuncs);
  //const id = useMemo(()=>getId(), [getId]);
  useEffect(()=>{
    setVError({key:id, value:error});
    return ()=> setVError({key:id, value:null});
  }, [error, id, setVError])

  if(!show && required) return <span>{lang["eRequired"]}</span>
  if(!show && !required) return <span></span>

  return error?<span className="invalid">{lang[error]}</span>:<span className="valid"></span>
};

export const vBN = v => {
  if (!(/^\d{9}$/.test(v)) || v === "000000000") {
    return "eInvalidBN";
  }
}

export const vEmail = email => {
  return /\S+@\S+\.\S+/.test(email) ? "" : "eInvalidEmail";
}


export const vErrorReducer = (preState, err) => {
  const state = {...preState}
  if (err.value) {
    state[err.key]=err.value;
    //state.add(err.key);
  } else {
    delete state[err.key];
    //state.delete(err.key);
  }
  //console.log("verrors state", state, Object.keys(state).length);
  return state;
}
