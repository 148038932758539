import React, {useContext} from 'react';
import {FieldsBox} from "./FieldsBox";
import {FormHeader} from "./Layouts";
import {I18NContext} from "./Contexts";

export const HelpPage=({onBack})=>{
  const lang=useContext(I18NContext);
  return <>
    <FormHeader>{lang.helpTitle}</FormHeader>
    <div style={{maxWidth:"100%", backgroundColor:"#ffffff", margin:"0", padding:"10px 0"}}>
      <div className="row">
        <div className="large-12 columns">
          <div name="registration" id="registration"></div>
          <FieldsBox>
            <div className="row">    	
              <div className="large-12 columns">
                <button className="button primary" onClick={onBack}> {lang.returnToForm} </button>
              </div>
            </div>
            <div className="row align-middle"> 
            {
              lang.lang === "en"? <div className="large-10 columns" tabIndex="0">
                <p>To continue with your registration, please click Return to Form at the top of this page.</p>
                <strong>General Tips and Advice</strong>
                <p>• Ensure that you have reviewed all information and completed all required fields on the form before you submit.
                  <br/> Use the Return to Form button to continue with your registration.
                </p>

                <strong>Payment Information</strong>
                <p>• If registering for a paid event, you will be sent to a separate page to complete the payment.<br/>
                  After payment is complete, you may print and/or save a copy of your registration for your records.
                </p>

                <strong>Frequently Asked Questions</strong>

                <p>How Do I Cancel the Registration?<br/>
                  To cancel your registration before completing the form, simply close your internet browser.<br/>
                  To cancel your registration acter completing the form, please contact the Event Organizer.
                </p>

                <p>Why do I see the “The event registration is not available at this time.” message?<br/>
                  This may mean that the event has been passed, or the event attendee capacity has been reached, or there is a technical malfunction. Please contact the Event Organizer for assistance.
                </p>

                <p>What if I know I'm in your database but I can't find my company in the list?<br/>
                  Please contact the Event Organizer for assistance.
                </p>

                <p>Have more questions?<br/>
                  Please click the Return to Form button and contact the Event Organizer.
                </p>
              </div>
              :
              <div className="large-10 columns" tabIndex="0">
                <p>Pour poursuivre votre inscription, veuillez cliquer sur « Revenir au formulaire » au haut de cette page.</p>
                <strong>Conseils et astuces générales</strong>
                <p>– Assurez-vous d’avoir vérifié tous les renseignements et rempli tous les champs obligatoires du formulaire avant de le soumettre.
                  <br/> Utilisez le bouton « Revenir au formulaire » pour poursuivre votre inscription.
                </p>

                <strong>Renseignements relatifs au paiement</strong>
                <p>– Si vous vous inscrivez à un événement payant, vous serez dirigé vers une page distincte pour effectuer le paiement.<br/>
                  Une fois le paiement effectué, vous pourrez imprimer et sauvegarder une copie de votre inscription pour vos dossiers.
                </p>

                <strong>Foire aux questions</strong>

                <p>Comment puis-je annuler mon inscription?<br/>
                  Pour annuler votre inscription avant d’avoir rempli le formulaire, fermez simplement votre navigateur Web.
                  Pour annuler votre inscription après avoir rempli le formulaire, veuillez communiquer avec l’organisateur de l’événement.
                </p>

                <p>Pourquoi le message « L’inscription à l’événement n’est pas disponible pour l’instant » s’affiche-t-il?<br/>
                  Cela peut signifier que l’événement est passé, que le nombre maximal de participants a été atteint, ou encore qu’il y a un problème technique. Veuillez communiquer avec l’organisateur de l’événement pour obtenir de l’aide.
                </p>

                <p>Que faire si je sais que je suis dans votre base de données, mais que je ne trouve pas mon entreprise dans la liste?<br/>
                  Veuillez communiquer avec l’organisateur de l’événement pour obtenir de l’aide.
                </p>

                <p>Vous avez d’autres questions?<br/>
                  Veuillez cliquer sur le bouton « Revenir au formulaire » et communiquer avec l’organisateur de l’événement.
                </p>
              </div>
            }
            </div> 
          </FieldsBox>
        </div>
      </div>
    </div>
  </>;
}

