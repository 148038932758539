import React, { useContext, useRef, useEffect, useState, useCallback, useReducer, useMemo } from 'react';
import { FieldsBox } from "./FieldsBox";
import { ErrorSection} from "./ErrorSection";
import { I18NContext, ValidateContext } from "./Contexts";
import { Validator, vBN, vEmail, vErrorReducer } from "./Validator";

const Field = ({name, value})=><div tabIndex="0"><strong>{name}:</strong> <span>{value}</span></div>;
const toggleItem = (list, item)=>list.includes(item)?list.filter(x=>x!==item):[...list, item];

export const ConfirmParticipation = ({ event, company, attendees, contact, payParams, legalAgreementUrl, setLegalAgreementUrl, onContinue, initData}) => {
  const lang = useContext(I18NContext);
  const [showValidator, setShowValidator] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectCost, setSelectCost] = useState((!payParams.wholecost && !payParams.outreachcost)?null:payParams.wholecost?["wholecost"]:["outreachcost"]);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [authorityChecked, setAuthorityChecked] = useState(false);
  const [understandChecked, setUnderstandChecked] = useState(false);
  const [vErrors, setVError] = useReducer(vErrorReducer, {});
  const [errorSectionErrors, setErrorSectionErrors] = useState(null);

  const vContext = useMemo(() => {
    return { show: showValidator, setVError };
  }, [showValidator]);


  const [signature, setSignature] = useState(initData?.signature || {
    title:"",
    firstname:"",
    lastname:"",
    companyname:company.CompanyName||"",//company.CompanyName,
    email:"",//contact.email,
    bnumber:"",// company.BusinessNumber,
    signature:"",
    legalAgreementUrl:""
  });
  const handleChange=useCallback(e=>{
    const {name, value} = e.target;
    setSignature(prev=>({...prev, [name]:value}));
  }, []);
  const focusRef = useRef();
  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);
  const feeAmount = (selectCost||[]).reduce((s,c)=>s+payParams[c], 0);
  const agreementParams={
                CompanyName:company.CompanyName,
                ...(({eventagreementtype, eventcity, eventstart, eventend})=>({eventagreementtype, eventcity, eventstart, eventend}))(event),
                feeAmount
                };
  return <div style={{ maxWidth: "100%", backgroundColor: "#ffffff", margin: "0", padding: "10px 0" }}>
    <div className="row">
      <div className="large-12 columns">
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns">
              <legend tabIndex="0" ref={focusRef}>{lang.lang === "fr" ? event.eventnamefr : event.eventname}</legend>
              <Field name={lang.cpeType} value={event.eventtype}/>
              <Field name={lang.cpeDates} value={event.eventdate}/>
              <Field name={lang.cpeLocation} value={event.eventloc}/>
              <Field name={lang.cpeSector} value={event.eventsector}/>
              <div tabIndex="0"><strong>{lang.cpeDescription}:</strong></div>
              <div className="border-box" tabIndex="0">
                <pre>{lang.lang === "fr" ? event.eventdespfr : event.eventdesp}</pre>
              </div>
            </div>
          </div>
        </FieldsBox>
        <ValidateContext.Provider value={vContext}>
        {errorSectionErrors && <ErrorSection errors={errorSectionErrors}/>}
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns">
              <legend tabIndex="0">{lang.cpCost} {selectCost&&<Validator id={"cpCost"} value={selectCost.length} />}</legend> 
              {selectCost?<>
                {payParams.wholecost>0&&<><input type="checkbox" id="wholecost" disabled={payParams.outreachcost===0} checked={selectCost.includes("wholecost")} onChange={e=>setSelectCost(toggleItem(selectCost, "wholecost"))}/>
                <label tabIndex="0" for="wholecost">{lang.cpcWhole}: ${payParams.wholecost}</label><br></br></>}
                {payParams.outreachcost>0&&<><input type="checkbox" id="outreachcost" disabled={payParams.wholecost===0} checked={selectCost.includes("outreachcost")} onChange={e=>setSelectCost(toggleItem(selectCost, "outreachcost"))}/>
                <label tabIndex="0" for="outreachcost">{lang.cpcOutreach}: ${payParams.outreachcost}</label><br></br> </>}
              </>:<>{lang.feeWaived}</>}
            </div>
          </div>
        </FieldsBox>
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns">
              <legend tabIndex="0">{lang.cpCompanyInformation}</legend>
              <Field name={lang.cpcName} value={company.CompanyName}/>
              <Field name={lang.cpcWebsite} value={company.companyWebsite}/>
              <Field name={lang.cpcContactName} value={`${contact.firstname} ${contact.lastname}`}/>
              <Field name={lang.cpcContactTitle} value={contact.title}/>
              <Field name={lang.cpcContactEmail} value={contact.email}/>
            </div>
          </div>
        </FieldsBox>
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns">
              <legend tabIndex="0">{lang.attendeeInformation}:</legend>
              <div tabIndex="0">{lang.cpaAttendeeDesc}</div>
              <div className="border-box">
                {attendees.map(a => <div tabIndex="0" key={a.email} className="margin-3 padding-3">
                  {a.firstname} {a.lastname}<br/>
                  {a.phone&&<>{a.phone}<br/></>}
                  {a.email}<br/><br/>
                </div>)}
              </div>
            </div>
          </div>
        </FieldsBox>
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns" >
              <legend tabIndex="0" >{lang.cpTermsAndCondition}</legend>
              <label for="cpAgree" tabIndex="0"> <input type="checkbox" id="cpAgree" checked={agreeChecked} onChange={e=>{setAgreeChecked(!agreeChecked); setSignature(prev=>({...prev, ["legalAgreementUrl"]:window.location.protocol+"//"+ window.location.host+"/?agreement="+window.btoa(JSON.stringify(agreementParams))}));}}/>
              {lang.cpAgree}</label>
              <label for="cpAuthority" tabIndex="0"> <input type="checkbox" id="cpAuthority" checked={authorityChecked} onChange={e=>{setAuthorityChecked(!authorityChecked); setSignature(prev=>({...prev, ["legalAgreementUrl"]:window.location.protocol+"//"+ window.location.host+"/?agreement="+window.btoa(JSON.stringify(agreementParams))}));}}/>
              {lang.cpAuthority(agreementParams)}</label>
              <label for="cpUnderstand" tabIndex="0"> <input style={{marginBottom:0}} type="checkbox" id="cpUnderstand" checked={understandChecked} onChange={e=>{setUnderstandChecked(!understandChecked); setSignature(prev=>({...prev, ["legalAgreementUrl"]:window.location.protocol+"//"+ window.location.host+"/?agreement="+window.btoa(JSON.stringify(agreementParams))}));}}/>
              {lang.cpUnderstand(agreementParams)}</label><br/>
              <div tabIndex="0">{lang.cpSignature}</div><br/>
              {/*<ValidateContext.Provider value={{ show: showValidator, getId: () => 0, setVError: () => { } }}>*/}
                <div className="row">
                  <div className="large-6 columns">
                    <label htmlFor={"companyname"} tabIndex="0">{lang.cpcName}: <Validator id={"cpcName"} value={signature.companyname} /></label>
                    <input type="text" name="companyname" id={"companyname"} value={signature.companyname} onChange={handleChange} size="20" />
                  </div>
                  <div className="large-6 columns">
                    <label htmlFor={"firstname"} tabIndex="0">{lang.firstName}: <Validator id={"firstName"} value={signature.firstname} /></label>
                    <input type="text" name="firstname" id="firstname" value={signature.firstname} onChange={handleChange} size="20" />
                  </div>
                  <div className="large-6 columns">
                    <label htmlFor={"lastname"} tabIndex="0">{lang.lastName}: <Validator id={"lastName"} value={signature.lastname} /></label>
                    <input type="text" name="lastname" id={"lastname"} value={signature.lastname} onChange={handleChange} size="20" />
                  </div>
                  <div className="large-6 columns">
                    <label htmlFor="title" tabIndex="0">{lang.cpOfficeTitle}: <Validator id={"cpOfficeTitle"} value={signature.title} /></label>
                    <input type="text" name="title" id="title" value={signature.title} onChange={handleChange} size="20" />
                  </div>
                  <div className="large-6 columns">
                    <label htmlFor={"email"} tabIndex="0">{lang.email}: <Validator id={"email"} value={signature.email} vFuncs={[vEmail]}/></label>
                    <input type="text" name="email" id={"email"} value={signature.email} onChange={handleChange} size="20" />
                  </div>
                  <div className="large-6 columns">
                    <label htmlFor={"bnumber"} tabIndex="0">{lang.businessNumber}: <Validator id={"businessNumber"} value={signature.bnumber} vFuncs={[vBN]} /></label>
                    <input type="text" name="bnumber" id={"bnumber"} value={signature.bnumber} onChange={handleChange} size="20" />
                  </div>
                </div>
                <div className="row">
                  <div className="large-7 columns">
                    <label htmlFor={"signature"} tabIndex="0">{lang.cpElectronicSignature}: <Validator id={"cpElectronicSignature"} value={signature.signature} /></label>
                    <input type="text" name="signature" id={"signature"} value={signature.signature} onChange={handleChange} size="20" />
                  </div>
                </div>
                <div className="text-right">
                  {processing ?
                    <button className="button" style={{ display: "inline-block" }}><i className='fa fa-spinner fa-spin'></i>{lang.processing}</button>
                    :
                    <button className="button" style={{ display: "inline-block" }} onClick={e=>{
                      const agreementChecked = agreeChecked&&authorityChecked&&understandChecked;
                      if (Object.keys(vErrors).length || !agreementChecked) {
                        setShowValidator(true);
                        //setValidateMessage(lang.eInvalidGenaral);
                        setErrorSectionErrors({...(agreementChecked?{}:{"":"eAgreementNotChecked"}), ...vErrors});
                        return;
                      }else{
                        setProcessing(true);
                        setLegalAgreementUrl(window.location.protocol + "//" + window.location.host + "/?agreement=" + window.btoa(JSON.stringify(agreementParams)));                      
                        onContinue({...payParams, payamount:feeAmount}, {save:{signature}, massageRequest:request=>{                         
                          return {...request, delegation:signature, wcost:payParams.wholecost, ocost:payParams.outreachcost}
                        }});
                      }
                    }}>{lang.cpPay}</button>
                  }
                </div>
            </div>
          </div>
        </FieldsBox>
        </ValidateContext.Provider>
      </div>
    </div>
  </div>
}
