import React from 'react';

const i18n = {
  en: {
    lang: "en",
    switchTo: "Français",
    switchToAbbr: "FR",
    eventRegistration: "Event Registration",
    applicationForm:"Application Form",

    loading: "loading",
    eventInformation: "Event Information",
    mapIt: "Map It",
    eventName: "Event Name",
    eventDate: "Event Date(s):",
    eventRegisterFee: "Registration Fee (per attendee):",
    eventRegisterFee_flexible: "Registration Fee:",
    eventOrg: "Event Organizer",
    eventOrgPhone: "Event Organizer Phone:",
    eventOrgEmail: "Event Organizer Email:",
    eventOrgAddress: "Event Organizer Address",
    eventDescription: "Event Description:",
    footNeedHelp: event => <>If you need help or have any questions, please contact the event organizer by phone at {event.eventhelp} or email <a href={"mailto:" + event.orgemail}>{event.orgemail}</a></>,
    footLinkHelp: "Help",
    footLinkAccessibility: "Accessibility",
    footLinkAccessibilityUrl: "https://www.ontario.ca/page/accessibility",
    footLinkPrivacy: "Privacy",
    footLinkPrivacyUrl: "https://www.ontario.ca/page/privacy-statement",
    footLinkTOU: "Terms of use",
    footLinkTOUUrl: "https://www.ontario.ca/page/terms-use",
    registerNow: "Register Now",
    copyright: "© King’s Printer for Ontario, 2022",

    isYourCompany: name => `Is "${name}" your company?`,
    searchCompany: "Search to see if your company's information is on file. ",
    disclaimer:
    "Note: If you are a member of the Municipal, Provincial or Federal Government, please do not register via this link. Contact the event organizer directly to participate in the event.",
    search: "Search",
    searchPrompt: "What is your company's name?",
    maxSearch: "You may perform up to 3 searches to select a company.",
    reachSearchLimit: 'Please select "Add New Company" to continue',
    foundXCompanies: (count, searchStr) => `Found ${count} companies for ${searchStr} (select one to continue or  choose Add New Company)`,
    addNewCompany: "Add New Company",
    pleaseSelect: "Please select", //missed

    companyInformation: "Company Information",
    "companyInformation:": "Company Information:",
    companyLegalName: "Company's legal name",
    businessNumber: "Business Number (CRA Identification Number)",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    city: "City",
    postCode: "Postal Code",
    province: "Province",
    country: "Country",
    companyFoundDate:"Year company was established",
    companyWebsite:"Company Website",
    continue: "Continue",
    form2Continue: "Apply now",
    yes: "Yes",
    no: "No",
    unsure:"Unsure",
    bnhint: "Insert only numbers, nine digits",

    attendeeInformation: "Attendee Information",
    addAttendee: "Who will be attending the event?",
    minAttendee: "Please add at least one attendee.",
    maxAttendee: n=>`You can add up to ${n} attendees.`,
    addAnother: "Add another attendee",
    seatsLeft: sl => `Only ${sl} spots left.`,
    deleteattendee: "Delete Attendee",

    firstName: "First Name",
    lastName: "Last Name",
    titlePrefix: "Job Title",
    email: "Email Address",
    phone: "Phone (Number Only)",
    optional: "(Optional)",

    checkoutInfo: "Checkout",
    yourTotalFee: "Your total fee is",
    totalFee: "Total fee",
    feeWaived: "No fee",
    noCharge: "There is no fee for this event",
    processing: "Processing",
    register: "Register",
    registerAndPay: "Register and Pay",
    attention: "Attention",
    eventNA: <>
      <p>The system is experiencing technical difficulties.</p>

      <p>
        Please contact the Event Organizer or email <a href='mailto:trade.officer@ontario.ca'>trade.officer@ontario.ca </a> for assistance.
      </p>
    </>,

    form1SummarySuccess: name => <>
      <h4>Success! You are now registered for: {name}</h4>
      <p>An email confirmation has been sent to the attendees. Your event access link will be sent to attendees by email prior to the event.</p>
    </>,
    form2SummarySuccess: name => <>
      <h4>Success! We have received your application.</h4>
      <p>An email confirmation has been sent to the attendees. An event organizer will contact you shortly with the next steps.</p>
    </>,
    form3SummarySuccess: name => <>
      <h4>Success! We have received your payment and you are now registered.</h4>
      <p>An email confirmation has been sent to the attendees. An event organizer will contact you shortly with the next steps.</p>
    </>,

    paySummary: "Pay Summary",
    submissionSummary: "Event Registration - Submission Summary",
    summaryThankyou: "Thank you for submitting your request. You may print this page as receipt of your transaction.",
    noticeOfCollection: <><strong>Notice of Collection:</strong> {"The Ontario Government (Ontario) collects the information the applicant provides on this website for the purposes of (i) determining the applicant’s eligibility for participation in the mission, and (ii) communicating with the applicant about its application. The Government of Ontario (Ontario) is bound by the Freedom of Information and Protection of Privacy Act, R.S.O. 1990, c. F.31 (FIPPA), as amended from time to time, and any such information provided to Ontario may be subject to disclosure under the FIPPA."}</>,
    noticeOfCollection2Step: <><strong>Notice of Collection:</strong> {"The Ontario Government (Ontario) collects the information the applicant provides on this website for the purposes of (i) determining the applicant’s eligibility for participation in the mission, and (ii) communicating with the applicant about its application. The Government of Ontario (Ontario) is bound by the Freedom of Information and Protection of Privacy Act, R.S.O. 1990, c. F.31 (FIPPA), as amended from time to time, and any such information provided to Ontario may be subject to disclosure under the FIPPA."}</>,
    receiveInfo:"Yes,  I would like to receive information about similar missions and related programs from the Ontario Ministry of Economic Development, Job Creation and Trade in the future.",

    summaryApplicationDetail: "Application Detail:",
    summaryRegistrationDetail: "Registration Detail:",

    summaryDetail: "Detail of your submission:",
    summaryNextTitle: "Next Steps",
    summaryNextContent: "A ministry will contact you if additional information if required to process your request. If you have any questions about your request, please contact the ministry representative for this event.",
    summaryRegStatus: "Register Status",
    dateTime: "Date/time",
    subCN: "Confirmation number",
    payCN: "Payment Confirmation number",
    cardType: "Card Type",
    submitTo: "Submitted To",
    submitToAddr: "Ministry of Economic Development Job Creation and Trade",
    viewCalendar: <>
      View the Ontario <a href="https://www.ontario.ca/tradecalendar/search" rel="noopener noreferrer" target="_blank">Trade Calendar</a> for more events and missions
    </>,
    helpTitle: "Event Registration - help",
    returnToForm: "Return to Form",

    //search validate
    errorTitle:"Errors",
    eRequired: "(Required)",
    e3char: "Please enter at least three characters of the company name and click Search.",
    eInvalidBN: "Please enter a 9-digit Business Number (BN)",
    eInvalid: "Invalid",
    eInvalidEmail: "Invalid email format",
    eInvalidPhone: "Invalid phone number",
    eInvalidPay: "(Please enter a fee between $1 and $100,000)",
    eInvalidPostalCode: "Please enter postal code in A#A #A# format.",
    eInvalidGenaral: "Please complete required fields.",
    eInvalidFoundYear:"Year should be 4 digits",
    eAgreementNotChecked:"Not all agreements are checked off",
    //eBNFormat:"Business Number should be 9 digits",

    attendee:"Attendee",
    eventContact:"Event Contact",

    cpTitle: "Delegate Participation Agreement",
    cpEventInformation:"Information about the event",
    cpeType:"Type of event",
    cpeDates:"Dates",
    cpeLocation:"Location(market region)",
    cpeSector:"Sector",
    cpeDescription:"Description",

    cpCost:"Program fee",
    cpcWhole:"Trade Show/Trade Mission",//"The whole mission",
    cpcOutreach:"Mission Extension/outreach program to additional market(s)", //"Outreach",

    cpCompanyInformation:"Information about your company",
    cpOfficeTitle:"Official Title",
    cpcName:"Company name",
    cpcWebsite:"Company website",
    cpcContactName:"Contact name",
    cpcContactTitle:"Contact title",
    cpcContactEmail:"Contact email",

    cpaAttendeeDesc:"Who will be attending the event on behalf of your company?",
    cpaAttendeeList:"Attendee list",

    cpTermsAndCondition:"Confirm participation",
    cpAgree:"I agree that all of the information provided by my company is correct.",
    cpAuthority:params=><>I have the authority to bind the delegate ({params.CompanyName}) to <a href={"/?agreement="+window.btoa(JSON.stringify(params))} target="blank">the terms of agreement and Schedule A (Waiver, Release and Indemnity Form) for attending a trade mission</a>. </>,
    cpUnderstand:params=><>I understand that by agreeing to <a href={"/?agreement="+window.btoa(JSON.stringify(params))} target="blank">the terms of agreement and Schedule A (Waiver, Release and Indemnity Form) for attending a trade mission</a>. I am commiting the attendee listed above to attend the trade mission on behalf of my company</>,
    cpSignature: "The undersigned Delegate hereby acknowledges that is has read and understood the terms and conditions of the Agreement, and by providing its electronic signature to the Agreement, the Delegate agrees to be bound by all the terms and conditions contained herein",
    cpElectronicSignature:"Electronic signature(typed)",
    cpPay:"Continue to payment",

    // two step
    tsPrequalifyQuestions: "Pre-qualifying questions:",
    rOntario:"Is your business registered in Ontario?",
    mpsOntario:"Do you manufacture or produce a product or service in Ontario?",
    tsThanks:event=><>Thank you for your interest. For more information, please contact: <a href={"mailto:" + event.orgemail}>{event.orgemail}</a></>,
    tsAdditionalInfo:"Additional Information",
    tsNumOfEmployees:"Number of Employees",
    tsCompanySell:"What does your company sell?",
    tsService:"Service(s)",
    tsProduct:"Product(s)",
    tsBoth:"Both",
    tsIndustry:"What industry is your business in?",
    tsOther:"Other(please specify)",
    tsTotalRevenue:"Total annual revenues",
    tsI11l:"Are you currently selling products/services internationally?",
    tsI11lPct:"Percentage of your annual sales attributed to selling internationally?",
    tsI11lp1:"Less than 10%",
    tsI11lp2:"10%-30%",
    tsI11lp3:"30%-50%",
    tsI11lp4:"Greate than 50%",
    tsCountriesSell:"Which countries are you currently selling your products/services to?",
    tsCompanyGoal:"Identify your organization’s objectives for registering?",
    tsCompanyExpectations:<>Please provide a brief description of the type of business connections (example distributors, industry contacts, <abbr title="et cetera">etc.</abbr>) you would like to make.</>,
    tsCompanyProfile:<>Please provide a brief company profile. Make sure to include key differentiators about your products/services and recent projects (1000 characters max.)* (May be used in a company directory)</>,
    tsvCompanyExpectations:"Please provide a brief description of the type of business connections",
    tsvCompanyProfile:"Please provide a brief company profile.",
    setAsPContact:"Set as Event Contact",
    eventContact:"Event Contact",
    eventContactDesc:"This person will be contacted regarding the outcome of application",
    max1000c: "Maximum 1000 characters",

    expressInterest:"Express Interest",

    maxCapacityReached: (email) =>  `Thank you for your interest, we have reached maximum capacity for this event. Please visit Ontario.ca/tradecalendar to see what other events are coming up or reach out to ${email} for assistance.`,
    startdatePassed: (email) =>  `Thank you for your interest, this event has already taken place. If you are interested in more events like this, please contact the Event Organizer or email ${email} for assistance.`,
    registrationClosed: "Thank you for your interest, we have reached maximum capacity for this event. Please visit Ontario.ca/tradecalendar to see what other events are coming up or reach out to trade.officer@ontario.ca for assistance.",
    registeredByEmailLink: "Thank you for your interest, your company has already registered for this event. For further support, please contact the Event Organizer.",
    eventCancelled: "Thank you for your interest, this event has been cancelled. Please visit Ontario.ca/tradecalendar to see what other events are coming up or reach out to trade.officer@ontario.ca for assistance.",
    pickCompanyFromList: "Please look up your company name first, as we may already have your information in the database from a previous interaction. Add your company name ONLY if it does not appear in the selection below. Please review the company names carefully, choosing an incorrect company may cause issues with registration and payment.",

  },

  fr: {
    lang: "fr",
    switchTo: "English",
    switchToAbbr: "EN",
    eventRegistration: "Inscription à l’événement",
    applicationForm:"Formulaire de demand",

    paySummary: "Pay Summary FR",


    loading: "chargement",
    eventInformation: "Renseignements sur l’événement",
    mapIt: "Carte",
    eventName: "Event Name: - FR",
    eventDate: "Date(s) de l’événement:",
    eventRegisterFee: "Frais d’inscription (par participant):",
    eventRegisterFee_flexible: "Frais d’inscription:",
    eventOrg: "Organisateur de l’événement",
    eventOrgPhone: "Numero de téléphone de l'organisateur de l’événement:",
    eventOrgEmail: "Courriel de l'organisateur de l’événement:",
    eventOrgAddress: "Organisateur de l’événement Adresse",
    eventDescription: "Description de l’événement:",
    footNeedHelp: event => <>Si vous avez des questions ou si vous avez besoin d’aide, veuillez communiquer avec l’organisateur de l’événement par téléphone au {event.eventhelp} ou par courriel à <a href={"mailto:" + event.orgemail}>{event.orgemail}</a></>,
    footLinkHelp: "Aide",
    footLinkAccessibility: "Accessibilité",
    footLinkAccessibilityUrl: "https://www.ontario.ca/fr/page/accessibility",
    footLinkPrivacy: "Confidentialité",
    footLinkPrivacyUrl: "https://www.ontario.ca/fr/page/privacy-statement",
    footLinkTOU: "Conditions d’utilisation",
    footLinkTOUUrl: "https://www.ontario.ca/fr/page/terms-use",
    registerNow: "M’inscrire maintenant",
    copyright: "© Imprimeur du Roi pour l’Ontario, 2022",


    errorTitle:"Les erreurs",
    isYourCompany: name => `Est-ce que "${name}" est votre entreprise?`,
    searchCompany: "Faites une recherche pour savoir si les renseignements sur votre entreprise figurent dans le dossier.",
    disclaimer:
      "Remarque: Si vous êtes membre du gouvernement municipal, provincial ou fédéral, veuillez ne pas utiliser ce lien. Contactez l’organisateur de l’événement directement pour participer.",
    search: "Rechercher",
    searchPrompt: "Quel est le nom de votre entreprise?",
    maxSearch: "Vous pouvez effectuer jusqu’à 3 recherches pour sélectionner une entreprise.",
    reachSearchLimit: 'Veuillez sélectionner « Ajouter une nouvelle entreprise ».',
    foundXCompanies: (count, searchStr) => `${count} entreprises ont été trouvées pour ${searchStr}`,
    addNewCompany: "Ajouter une nouvelle entreprise",
    pleaseSelect: "Sélectionnez", //missed

    companyInformation: "Renseignements sur l’entreprise",
    "companyInformation:": "Renseignements sur l’entreprise:",
    companyLegalName: "Dénomination sociale de l’entreprise",
    businessNumber: "Numéro d’entreprise (numéro d’identification de l’ARC)",
    addressLine1: "Adresse (ligne 1)",
    addressLine2: "Adresse (ligne 2):",
    city: "Ville",
    postCode: "Code postal",
    province: "Province",
    country: "Pays",
    continue: "Continuez",
    form2Continue: "Inscrivez-vous maintenant",
    yes: "Oui",
    no: "Non",
    bnhint: "Insérer seulement des chiffres, neuf chiffres",

    attendeeInformation: "Renseignements sur les participants",
    addAttendee: "Qui participera à l’événement?",
    minAttendee: "Veuillez ajouter au moins un participant.",
    maxAttendee: n=>`Vous pouvez ajouter jusqu’à ${n} participants.`,
    addAnother: "Ajouter un autre participant",
    seatsLeft: sl => `Il ne reste que ${sl} places.`,
    deleteattendee: "supprimer un participant",

    firstName: "Prénom",
    lastName: "Nom de famille",
    titlePrefix: "Titre du poste",
    email: "Courriel",
    phone: "Téléphone (Chiffre seulement)",
    optional: "(facultatif)",

    checkoutInfo: "Checkout",
    yourTotalFee: "Le montant total à payer est",
    totalFee: "Total des frais",
    feeWaived: "Événement Gratuit",
    noCharge: "Événement Gratuit",
    processing: "Traitement",
    register: "M’inscrire",
    registerAndPay: "M’inscrire et payer",
    attention: "Attention",
    eventNA: <>
      <p>Le système connaît des difficultés techniques.</p>

      <p>
        Veuillez communiquer avec l’organisateur de l’événement ou envoyer un courriel à <a href='mailto:trade.officer@ontario.ca'>trade.officer@ontario.ca</a> pour obtenir de l’aide.
      </p>
    </>,

    summarySuccess: name => <>
      <h4>Bravo! Vous êtes maintenant inscrite ou inscrit à : {name}</h4>
      <p>Un courriel de confirmation a été envoyé aux participants. Le lien d’accès à l’événement sera transmis aux participants par courriel avant l’événement.</p>
    </>,

    //paySummary:"Pay Summary",
    submissionSummary: "Inscription à l’événement - Résumé de la soumission",
    summaryThankyou: "Thank you for submitting your request. You may print this page as receipt of your transaction.",
    noticeOfCollection: <><strong>Avis de collecte de renseignements :</strong> {"Le gouvernement de l’Ontario (l’Ontario) recueille les \ renseignements fournis par le demandeur sur le présent site Web dans le but i) de déterminer l’admissibilité du demandeur à la mission; et \ ii) de communiquer avec le demandeur au sujet de sa demande. Le gouvernement de l’Ontario (l’Ontario) est lié par la Loi sur l’accès à \ l’information et la protection de la vie privée, L.R.O. 1990, ch. F.31 (LAIPVP), telle que modifiée de temps à autre, et tout renseignement de ce type fourni à l’Ontario peut être divulgué en vertu de la LAIPVP. "}</>,
    summaryRegistrationDetail: "Détails d'inscription:",
    summaryApplicationDetail: "Renseignements sur la demande:",
    summaryDetail: "Detail of your submission:",
    summaryNextTitle: "Next Steps",
    summaryNextContent: "A ministry will contact you if additional information is required to process your request. If you have any questions about your request, please contact the ministry representative for this event.",
    summaryRegStatus: "Register Status",
    dateTime: "Date/heure",
    subCN: "Numéro de confirmation",
    payCN: "Numéro de confirmation du paiement",
    cardType: "Type de carte",
    submitTo: "Soumis à",
    submitToAddr: "Ministère du Développement économique, de la Création d’emplois et du Commerce",
    viewCalendar: <>
      Consultez le <a href="https://www.ontario.ca/fr/calendriercommerce/recherche" rel="noopener noreferrer" target="_blank">Calendrier des programmes et événements </a>liés au commerce international pour plus d’événements et de missions.
    </>,
    returnToForm: "Revenir au formulaire",
    helpTitle: "Renseignements sur l’événement - Aide",

    //search validate
    eRequired: "(Obligatoire)",
    e3char: "Veuillez saisir au moins trois caractères du nom de l’entreprise et cliquer sur Rechercher.",
    eInvalidBN: "Veuillez saisir un numéro d’entreprise (NE) à 9 chiffres",
    eInvalid: "Invalid",
    eInvalidEmail: "Format de courriel non valide",
    eInvalidPhone: "Numéro de téléphone non valide",
    eInvalidPay: "(Inscrivez un montant entre 1 $ et 100 000 $)",
    eInvalidPostalCode: "Veuillez saisir le code postal au format A#A #A#.",
    eInvalidGenaral: "Veuillez remplir les champs obligatoires.",
    //eBNFormat:"Business Number should be 9 digits",
    attendee:"Participant",



    //Sid Translations Below



    cpTitle: "Entente de participation des délégués",
    cpEventInformation:"Renseignements sur l’événement",
    cpeType:"Type d’événement",
    cpeDates:"Dates",
    cpeLocation:"Emplacement (région du marché)",
    cpeSector:"Secteur",

    cpCost:"Droits d’inscription au programme:",
    cpcWhole:"Exposition commerciale / Mission commerciale",
    cpcOutreach:"Extension de la mission / programme de visite d’autres marchés régionaux:",

    cpCompanyInformation:"Renseignements sur votre entreprise:",
    cpcName:"Nom de l’entreprise",
    cpcWebsite:"Site Web de l’entreprise",
    cpcContactName:"Nom de la personne-ressource",
    cpcContactTitle:"Titre de la personne-ressource",
    cpcContactEmail:"Courriel de la personne-ressource",

    attendeeInformation: "Renseignements sur le participant",
    cpaAttendeeDesc:"Qui assistera à l’événement au nom de votre entreprise?",
    cpaAttendeeList:"Liste des participants",

    cpTermsAndCondition:"Confirmer les participants.",
    cpAgree:"Je conviens que tous les renseignements fournis par mon entreprise sont exacts.",
    cpAuthority:params=><>J’ai le pouvoir de lier le délégué ({params.CompanyName}) aux <a href={"/?agreement="+window.btoa(JSON.stringify(params))} target="blank">conditions de la convention et de l’annexe A (formulaire d’exonération de responsabilité et d’indemnisation) dans le cadre de sa participation à une mission commerciale</a>. </>,
    cpUnderstand:params=><>Je comprends qu’en acceptant <a href={"/?agreement="+window.btoa(JSON.stringify(params))} target="blank">les conditions de l’accord et de l’annexe A (formulaire d’exonération de responsabilité et d’indemnisation) concernant la participation à une mission commerciale</a>.  Je m’engage à ce que le participant mentionné ci-dessus participe à la mission commerciale au nom de mon entreprise.</>,
    cpSignature: "Par la présente, le délégué soussigné reconnaît avoir lu et compris les conditions de l’entente et, en apposant sa signature électronique à l’entente, accepte d’être lié par toutes les conditions qui y sont énoncées.",
    cpOfficeTitle:"Titre officiel",

    firstName: "Prénom",
    lastName: "Nom de famille",
    titlePrefix: "Titre du poste",
    email: "Courriel",
    phone: "Numéro de téléphone",
    optional: "(facultatif)",

    cpElectronicSignature:"Signature électronique (typographiée)",
    cpPay:"Procéder au paiement",
  
    tsPrequalifyQuestions: "Questions de préqualification:",
    rOntario:"Votre entreprise est-elle enregistrée en Ontario?",
    mpsOntario:"Fabriquez-vous ou produisez-vous un produit ou un service en Ontario?",
    
    tsThanks:event=><>Nous vous remercions de votre intérêt. Pour obtenir de plus amples renseignements, veuillez communiquer avec: <a href={"mailto:" + event.orgemail}>{event.orgemail}</a></>,

    companyFoundDate:"Année de fondation de l’entreprise",
    companyWebsite:"Site Web de l’entreprise",
    tsAdditionalInfo:"Renseignements supplémentaires:",
    tsNumOfEmployees:"Nombre d’employé(e)s",
    tsCompanySell:"Que vend votre entreprise?",
    "Service(s)":"Service(s)",
    "Product(s)":"Produit(s)",
    Both:"Les deux",
    tsIndustry:"À quelle industrie appartient votre entreprise?",
    Aerospace:"Aérospatiale",
    "Agriculture and Food Processing":"Agriculture et transformation des aliments",


    "Business & Professional Services":"Services commerciaux et professionnels",
    "Chemicals":"Produits chimiques",
    "Construction and Infrastructure":"Construction et infrastructure",
    "Cultural Industries":"Secteurs culturels",
    "Defense & Security":"Défense et sécurité",
    "Digital Media":"Médias numériques",
    "Energy & Fuels (incl. Smart Grid)":"Énergie et combustibles (y compris le réseau intelligent)",
    "Energy, Renewable":"Énergie renouvelable",
    "Environment":"Environnement",
    "Financial Services":"Services financiers",
    "Forestry":"Foresterie",
    "Information and Communication Technologies":"Technologie de l’information et des communications",
    "Life Sciences":"Sciences de la vie",
    "Manufacturing, General":"Fabrication générale",
    "Mining":"Exploitation minière",
    "Motor Vehicles":"Véhicules automobiles",
    "Primary Metals":"Métaux de première transformation",
    "Retailers & Wholesalers":"Détaillants et grossistes",
    "Transportation Equipment & Services":"Matériel et services de transport",
    "Other":"Autre",
    tsOther:"Autres (veuillez préciser)",
    tsTotalRevenue:"Total des revenus annuels",
    "No net revenue (pre-commercial)":"Aucun revenu net (activité précommerciale)",

    "Less than $100,000":"Moins de 100 000 $",
    "$100,000 ~ $999,999":"100 000 $ à 999 999 $",
    "$1 million ~ $10 million":"De 1 million $ à 10 millions $",
    "$10 million ~ $50 million":"De 10 million $ à 50 millions $",
    "$50 million ~ $100 million":"De 50 million $ à 100 millions $",
    "More than $100 million":"Plus que 100 millions $",

    unsure:"Je ne sais pas",

    tsI11l:"Vendez-vous actuellement des produits / services à l’internationale?",
    tsI11lPct:"Pourcentage de vos ventes annuelles attribué aux exportations?",
    "Less than 10%":"Moins de 10 %",
    "10-30%":"10 à 30 %",
    "30-50%":"30 à 50 %",
    "Greater than 50%":"Plus de 50 %",
    tsCountriesSell:"Dans quels pays vendez-vous actuellement vos produits / services?",
    tsCompanyGoal:"Énoncez les objectifs d’inscription de votre entreprise",


    "Sell your company's products/services to a new customer":"Vendre les produits / services de votre entreprise à un nouveau client",
    "Seek a representative(s), distributor(s) or trade partner(s)":"Trouver un ou des représentants, distributeurs ou partenaires commerciaux",
    "Identify investment partners":"Identifier des partenariats d'investissement",
    "Identify or develop research collaborations":"Cerner ou créer des occasions de collaboration en recherche",
    "Identify partners for joint venture(s) or technology licensing and transfer":"Identifier des partenaires de coentreprise(s) ou de permis et transfert de technologie",
    "Identify partners for manufacturing":"Identifier des partenaires de fabrication",
    "Market research/competitor research":"Faire une étude de marché / de la concurrence",
    "Meet existing representative(s) or customer(s)":"Rencontrer un ou des représentants ou clients existants",
    "Meet with government, regulatory and/or industry associations":"Rencontrer les associations gouvernementales, réglementaires et (ou) sectorielles",

    receiveInfo:"Oui, j’aimerais recevoir à l’avenir des renseignements à propos de missions similaires et de programmes connexes du ministère du Développement économique, de la Création d'emplois et du Commerce de l’Ontario.",

    noticeOfCollection2Step: <><strong>Avis de collecte de renseignements</strong> {"Le gouvernement de l’Ontario (l’Ontario) recueille les renseignements fournis par le demandeur sur le présent site Web dans le but i) de déterminer l’admissibilité du demandeur à la mission; et ii) de communiquer avec le demandeur au sujet de sa demande. Le gouvernement de l’Ontario (l’Ontario) est lié par la Loi sur l’accès à l’information et la protection de la vie privée, L.R.O. 1990, ch. F.31 (LAIPVP), telle que modifiée de temps à autre, et tout renseignement de ce type fourni à l’Ontario peut être divulgué en vertu de la LAIPVP."}</>,

    tsCompanyExpectations:<>Veuillez décrire brièvement le type de relations d’affaires (distributeurs, relations dans le secteur, <abbr title="et cetera">etc.</abbr>) que vous aimeriez établir.</>,
    tsCompanyProfile:<>Veuillez fournir un court profil d’entreprise. Assurez-vous d’inclure les principaux éléments différenciateurs de vos produits / services et projets récents. 
    Peuvent être utilisés dans un répertoire d’entreprises. </>,

    eventContact:"Organisateur de l’événement",
    eventContactDesc:"French  This person will be contacted regarding the outcome of application",
    max1000c: "Maximum de 1 000 caractères",
    eAgreementNotChecked:"Toutes les cases à cocher de l'accord ne sont pas cochées",


    expressInterest:"Déclarez intérêt",
    setAsPContact:"Définir comme contact d’événement",

    form1SummarySuccess: name => <>
    <h4>Bravo! Vous êtes maintenant inscrite ou inscrit à: {name}</h4>
    <p>Un courriel de confirmation a été envoyé aux participants. Le lien d’accès à l’événement sera transmis aux participants par courriel avant l’événement.</p>
  </>,
  form2SummarySuccess: name => <>
    <h4>Nous avons reçu votre demande!</h4>
    <p>Une confirmation par courriel a été envoyée aux participants. Un organisateur vous informera sous peu des prochaines étapes.</p>
  </>,
  form3SummarySuccess: name => <>
    <h4>Nous avons reçu votre paiement et vous êtes maintenant inscrit!</h4>
    <p>Une confirmation par courriel a été envoyée aux participants. Un organisateur vous informera sous peu des prochaines étapes.</p>
  </>,
    
    maxCapacityReached: (email) =>  `Merci de votre intérêt. Malheureusement, nous avons atteint la capacité maximale pour cet événement. Rendez-vous à ontario.ca/calendriercommerce pour connaître les prochains événements ou écrivez à ${email} si vous avez besoin d’aide.`,
    startdatePassed: (email) =>  `Merci de votre intérêt, mais l’événement est terminé. Pour en savoir plus sur les autres événements du genre, communiquez avec l’organisateur ou écrivez à ${email} si vous avez besoin d’aide. `,
    registrationClosed: "Merci de votre intérêt. Malheureusement, nous avons atteint la capacité maximale pour cet événement. Rendez-vous à ontario.ca/calendriercommerce pour connaître les prochains événements ou écrivez à trade.officer@ontario.ca si vous avez besoin d’aide.",
    registeredByEmailLink: "Merci de votre intérêt. Votre entreprise est déjà inscrite à l’événement. Pour obtenir de l’aide, communiquez avec l’organisateur.",
    eventCancelled: "Merci de votre intérêt, mais l’événement a été annulé. Rendez-vous à ontario.ca/calendriercommerce pour connaître les prochains événements ou écrivez à trade.officer@ontario.ca si vous avez besoin d’aide.",
    pickCompanyFromList: "Commencez par chercher le nom de votre entreprise, car nous avons peut-être déjà vos renseignements dans notre base de données. N’ajoutez le nom que s’il n’apparaît pas ci-dessous. Vérifiez la liste attentivement, car choisir le mauvais nom pourrait nuire à votre inscription et au paiement.",
  }
};

i18n.fr = { ...i18n.en, ...i18n.fr };

export { i18n };
