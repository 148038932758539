import React, { useContext } from 'react';
import { I18NContext } from "./Contexts";

export const Header = ({ toggleLang }) => {
  const lang = useContext(I18NContext);
  return <header>
    <div style={{ maxWidth: "100%", backgroundColor: "#231f20", padding: "10px" }}>
      <div className="row">
        <div className="large-3 small-8 columns">
          <img src="https://www.ontario.ca//img/logo-ontario@2x.png" width="153" height="38" alt="Government of Ontario logo" />
        </div>
        <div className="large-6 float-left columns hide-for-small-only">
          <p style={{ margin: "10px 0 0 0" }}></p>
        </div>
        <div className="large-1 small-3 medium-2 columns float-right" style={{ textAlign: "right" }}>
          <button className="secondary button show-for-medium" onClick={toggleLang} style={{ margin: "0 0rem 0", padding: "4px 4px" }}>{lang.switchTo}</button>
          <button className="lang_switcher secondary button show-for-small-only" onClick={toggleLang} style={{ margin: "0 0rem 0", padding: "4px 4px" }}><abbr title={lang.switchTo}>{lang.switchToAbbr}</abbr></button>
        </div>
      </div>
    </div>
  </header>
}

export const FormHeader = (props) => {
  console.log("FormHeader", props);
  return <div style={{ maxWidth: "100%", backgroundColor: "#515151", color: "white", padding: "0.8rem" }} className="form-header-">
    <div className="row">
      <div className="large-12">
        <h1 className="large-8 columns" style={{ fontSize: "inherit" }} tabIndex="0">
          {props.children}
        </h1>
      </div>
    </div>
  </div>
}

export const Footer = ({ onShowHelp }) => {
  const lang = useContext(I18NContext);
  return <footer>
    <div className="footer">
      <div className="row">
        <div className="large-14 small-14 columns" style={{ marginTop: "2rem" }}>
          <ul className="footer-inline-list">
            <li><a href="/" onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onShowHelp();
            }}>{lang.footLinkHelp}</a></li> |
          <li><a href={lang.footLinkAccessibilityUrl} >{lang.footLinkAccessibility}</a></li> |
          <li><a href={lang.footLinkPrivacyUrl}>{lang.footLinkPrivacy}</a></li> |
          <li><a href={lang.footLinkTOUUrl}>{lang.footLinkTOU}</a></li>
          </ul>
          <p style={{ textAlign: "center" }}>{lang.copyright}</p>
        </div>
      </div>
    </div>
  </footer>
}
