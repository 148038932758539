import React, { useContext } from 'react';
import { FieldsBox } from "./FieldsBox";
import { I18NContext } from "./Contexts";

export const ErrorPage = (props) => {
  const lang = useContext(I18NContext);
  const { errorCode, emailAddress } = props;
  let errorMessage = '';
  console.log(errorCode, emailAddress);
  switch (errorCode) {
    case 'Event_Expired':
      errorMessage = lang.startdatePassed(emailAddress);
      break;
    case 'Registration_Closed':
      errorMessage = lang.registrationClosed;
      break;
    case 'Registration_is_Closed_No_Seats_Available':
      errorMessage = lang.maxCapacityReached(emailAddress);
      break;
    case 'Registration_Cancelled':
      errorMessage = lang.eventCancelled;
      break;
    case 'Registration_Duplicated':
      errorMessage = lang.registeredByEmailLink;
      break;
    default:
      errorMessage = lang.eventNA;
      break;
  }
  return <div>
    <FieldsBox>
      <div className="row">
        <div className="large-12 columns" tabIndex="0">
          <legend>{lang.attention}</legend>
        </div>
      </div>
      <div className="row align-middle">
        <div className="large-10 columns" tabIndex="0">
          {errorMessage}
        </div>
      </div>
    </FieldsBox>
  </div>;
}

