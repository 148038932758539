/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useCallback, useContext, useRef, useEffect } from 'react';
import { I18NContext, ValidateContext } from "./Contexts";
import { FieldsBox } from "./FieldsBox";
import { ENV, apiFetch } from "./constants";
import { Validator, vBN } from "./Validator";

export const SavedCompany = props => {
  const lang = useContext(I18NContext);
  const { company, useSaved, confirm } = props;
  const handleChange = useCallback(e => confirm(e.target.value === "true" ? company : null), [confirm, company]);

  const focusRef = useRef();
  useEffect(() => {
    console.log(focusRef.current);
    if (focusRef.current) {
      focusRef.current.focus();
      focusRef.current = null;
    }
  });

  console.log("useSaved", useSaved);
  return <FieldsBox>
    <div className="row">
      <div className="large-12 columns" tabIndex="0" ref={focusRef}>
        <legend>{lang.isYourCompany(company.CompanyName)}</legend>
      </div>
    </div>
    <label>
      <input type="radio" name="use" value="true" checked={!!useSaved} onChange={handleChange} /> {lang.yes}
    </label>
    <label>
      <input type="radio" name="use" value="false" checked={useSaved === false} onChange={handleChange} /> {lang.no}
    </label>
  </FieldsBox>
}

const vPostalCode = v => {
  if (!(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v))) {
    return "eInvalidPostalCode"
  }
}

const vFoundDate = v => {
  if (v.length && v.length!==4) {
    return "eInvalidFoundYear";
  }
}

const vCompanyName = v => {
  const {value, tryCount} = v;
  if(tryCount>=3) return "reachSearchLimit";
  if (!value) return "eRequired";
  if (value.length < 3) return "e3char";
}


export const SearchCompany = props => {
  const lang = useContext(I18NContext);
  const { selectedCompany, onSelectedCompany, searchResult, setSearchResult, searchText, setSearchText } = props;
  const [showValidator, setShowValidator] = useState(0);
  const searchCompany = useCallback(async (searchText) => {
    const data = await apiFetch(ENV.API.searchCompany, { companyname: searchText });
    setSearchResult({ companies: data, searchText });
  }, [setSearchResult]);
  const [count, setCount] = useState(0);

  const focusRef = useRef();
  const inputRef = useRef();
  const errorRef = useRef();

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
      focusRef.current = null;
    }
  }, []);

  return <><FieldsBox>
    <div className="row">
      <div className="large-12 columns" >
        <legend tabIndex="0" ref={focusRef}>{lang.searchCompany}</legend>
        <p style={{ fontWeight: "700" }}>{lang.disclaimer}</p>
        <p tabIndex="0">{lang.pickCompanyFromList}</p>
      </div>
      <ValidateContext.Provider value={{ show: showValidator, getId: () => 0, setVError: () => { } }}>
        <div className="large-8 columns">
          <label htmlFor="company-search" tabIndex="0" ref={errorRef}>{lang.searchPrompt} <Validator id="searchPrompt" value={{tryCount:count, value:searchText}} vFuncs={[vCompanyName]} /></label>
          <input ref={inputRef} id="company-search" type="text" value={searchText} onChange={e => setSearchText(e.target.value)} size="20" />
        </div></ValidateContext.Provider>
      <div className="large-4 columns" style={{ paddingTop: "1.7rem" }}>
        <button className="button" style={{ display: "inline-block" }} onClick={e => {
          if (vCompanyName({tryCount:count, value:searchText})) { setShowValidator(true); errorRef.current.focus(); return; }
          searchCompany(searchText);
          setShowValidator(false);
          setCount(count + 1);
        }}>{lang.search}</button>
        {/*count > 3 && <span className="invalid" tabIndex="0" style={{ marginLeft: "1rem" }} ref={focusRef}>{lang.reachSearchLimit}</span>*/}
      </div>
    </div>
  </FieldsBox>
    {searchResult && <FieldsBox>
      <div className="row" id="search-results">
        <div className="large-12 columns" tabIndex="0" ref={focusRef}>
          <legend>{lang.foundXCompanies(searchResult.companies.length, searchResult.searchText)}</legend>
        </div>
      </div>
      {searchResult.companies.map(x => <label key={x.CompanyName}>
        <input type="radio" value={x.CompanyName} key={x.CompanyName} checked={selectedCompany === x} onChange={e => onSelectedCompany(x)} tabIndex="0" />
        {x.CompanyName}
      </label>)}
      <label> <input type="radio" value="" checked={selectedCompany === ""} onChange={e => onSelectedCompany("")} /> <strong>{lang.addNewCompany}</strong>  </label>
    </FieldsBox>
    }
  </>
}

const Province = ({ provs, onChange, value }) => {
  const lang = useContext(I18NContext);
  return provs ? <select name="CompanyProv" onChange={onChange} value={value}>
    <option value="">{lang.pleaseSelect}</option>
    {provs.map(p => <option key={p} value={p}>{p}</option>)}
  </select>
    :
    <input type="text" name="CompanyProv" value={value} onChange={onChange} />;
}

export const CompanyInfo = props => {
  const lang = useContext(I18NContext);
  const { company, onChange, bnrequired, cp } = props;
  const handleChange = useCallback(e => {
    let {name, value} = e.target;
    if(name==="accountfounddate") value = value.replace(/[^0-9]/gi, '').slice(0,4);
    onChange({ ...company, [name]: value });
  }, [onChange, company]);

  const focusRef = useRef();
  useEffect(() => {
    console.log(focusRef.current);
    if (focusRef.current) {
      focusRef.current.focus();
      focusRef.current = null;
    }
  });

  if (!cp) return <div className="text-center"><h1><i className='fa fa-spinner fa-spin'></i>{lang.loading}</h1></div>;
  return <FieldsBox>
    <div className="row">
      <div className="large-12 columns" tabIndex="0" ref={focusRef}>
        <legend>{lang.companyInformation}</legend>
      </div>
    </div>
    <div className="row">
      <div className="large-6 columns">
        <label htmlFor="company-name">{lang.companyLegalName}: <Validator id="companyInformation:,companyLegalName" value={company.CompanyName}/></label>
        <input id="company-name" type="text" name="CompanyName" onChange={handleChange} value={company.CompanyName} size="20" />
      </div>
      <div className="large-3 columns">
        <label htmlFor="company-country">{lang.country}: <Validator id="companyInformation:,country" value={company.CompanyCountry} /></label>
        <select id="company-country" name="CompanyCountry" onChange={handleChange} value={company.CompanyCountry}>
          {cp.map(c => <option key={c.countryName} value={c.countryName}>{c.countryName}</option>)}
        </select>
      </div>
      <div className="large-3 columns">
        <label htmlFor="">{lang.province}: {["United States", "Canada"].includes(company.CompanyCountry) && <Validator id="companyInformation:,province" value={company.CompanyProv} />}</label>
        <Province id="" provs={cp.find(c => c.countryName === company.CompanyCountry).province} onChange={handleChange} value={company.CompanyProv} />
      </div>
    </div>
    <div className="row">
      <div className="large-6 columns">
        <label htmlFor="company-address">{lang.addressLine1}: <Validator id="companyInformation:,addressLine1" value={company.CompanyAddress} /></label>
        <input id="company-address" type="text" name="CompanyAddress" value={company.CompanyAddress} onChange={handleChange} />
      </div>
      <div className="large-6 columns">
        <label htmlFor="company-address2">{lang.addressLine2}</label>
        <input id="company-address2" type="text" name="CompanyAddress2" value={company.CompanyAddress2} onChange={handleChange} />
      </div>
    </div>
    <div className="row">
      <div className="large-3 columns">
        <label htmlFor="company-city">{lang.city}: <Validator id="companyInformation:,city" value={company.Companycity} /></label>
        <input id="company-city" type="text" name="Companycity" value={company.Companycity} onChange={handleChange} />
      </div>
      <div className="large-3 columns">
        <label htmlFor="company-postal">{lang.postCode}: <Validator id="companyInformation:,postCode" required={["United States", "Canada"].includes(company.CompanyCountry)} value={company.CompanyPostalCode} vFuncs={company.CompanyCountry === "Canada" ? [vPostalCode] : []} /></label>
        <input id="company-postal" type="text" name="CompanyPostalCode" value={company.CompanyPostalCode} onChange={e => onChange({ ...company, [e.target.name]: e.target.value.toUpperCase() })} />
      </div>
      {company.CompanyCountry === "Canada" && <div className="large-6 columns">
        <label htmlFor="company-bn9">{lang.businessNumber}: <Validator required={bnrequired === 'Y'} id="companyInformation:,businessNumber" value={company.BusinessNumber} vFuncs={[vBN]} /></label>
        <input id="company-bn9" maxLength="9" type="number" name="BusinessNumber" value={company.BusinessNumber} autoComplete="off" placeholder={lang.bnhint} size="9" onChange={handleChange} />
      </div>}
    </div>
    <div className="row">
      <div className="large-6 columns">
        <label htmlFor="company-founddate">{lang.companyFoundDate}:<Validator required={false} id="companyInformation:,companyFoundDate" value={company.accountfounddate} vFuncs={[vFoundDate]} /></label>
        <input id="company-founddate" type="text" name="accountfounddate" value={company.accountfounddate} onChange={handleChange} />
      </div>
      <div className="large-6 columns">
        <label htmlFor="company-website">{lang.companyWebsite}:</label>
        <input id="company-website" type="text"  name="accountwebsite" value={company.accountwebsite} onChange={handleChange} />
      </div>
    </div>
    <div className="row">
      <div className="large-10 columns text-right">&nbsp;</div>
      <div className="large-2 columns text-right">
        <button className="button" name="confirmed" value="true" onClick={handleChange}>{lang.continue}</button>
      </div>
    </div>
  </FieldsBox>;
}
