import React, {useContext, useState} from "react";
import { FieldsBox } from "./FieldsBox";
import { I18NContext } from "./Contexts";
import {COUNTRIES, COMPANY_SELL, INDUSTRIES, REVENUE_LEVELS, REVENUE_I11L_PERCENT, COMPANY_GOALS} from "./constants";
import { Validator} from "./Validator";

  /*const companymoreinfo = {
    "numberEmployee":"11-50",
    "companySell":"Both",
    "businessIndustry":"Agriculture and Food Processing;Chemicals;XXX",
    "otherIndustry":"other industry",
    "totalRevenue":"Less than $100,00",
    "currentSellingInt":"Yes",
    "percentSellInt":"Less than 10%",
    "sellcountry":"Albania;Canada;XXX",
    "regObjective":"Sell your company's products/services to a new customer;Seek a representative(s), distributor(s) or trade partner(s);XXX",
    "otherObject":"XX",
    "typeBusinessDesc":"XXX",
    "companyProfile":"XXX"};*/
export const AdditionalCompanyInfo = ({value, onChange, onContinue})=>{
  const lang = useContext(I18NContext);
  //const [info, onChange] = useState({businessIndustry:[], sellcountry:[], regObjective:[]});


  return <FieldsBox>
    <div className="row">
      <div className="large-12 columns" tabIndex="0">
        <legend >{lang.tsAdditionalInfo}</legend>
      </div>
    </div>
    <div className="row">
      <div className="large-4 columns">
        <label className="bold" htmlFor="num_employees" tabIndex="0">{lang.tsNumOfEmployees}: <Validator id="tsAdditionalInfo,: ,tsNumOfEmployees" value={value.numberEmployee}/></label>
        <select name="num_employees" id="num_employees" tabIndex="0" value={value.numberEmployee} onChange={e=>onChange({...value, numberEmployee:e.target.value})}>
          <option value="">{lang.pleaseSelect}</option>
          <option value="1-10">1 - 10</option>
          <option value="11-50">11 - 50</option>
          <option value="51-200">51 - 200</option>
          <option value="201-500">201 - 500</option>
          <option value="501-1000">501 - 1,000</option>
          <option value=">10000">&gt; 10,000</option>
        </select>
      </div>          
      <div className="large-12 columns">
        <fieldset>
          <label className="bold" tabIndex="0">{lang.tsCompanySell} <Validator id="tsAdditionalInfo,: ,tsCompanySell" value={value.companySell}/></label>
          <div onChange={e=>onChange({...value, companySell:e.target.value})}>
            {COMPANY_SELL.map(i=><label key={i} > <input type="radio" name="company_sell" value={i} id="company_sell_Services"/> {lang[i]||i}</label>)}
          </div>
        </fieldset>
      </div>
    </div>
    <div className="row">
      <div className="large-12 columns">
        <fieldset>
          <label className="bold" tabIndex="0">{lang.tsIndustry} <Validator id="tsAdditionalInfo,: ,tsIndustry" value={value.otherIndustry??value.businessIndustry.length}/></label>
          {INDUSTRIES.map(i=><div key={i} className="large-6 columns">
            <input type="checkbox" name="company_industry" checked={value.businessIndustry.includes(i)}
              value={i} id={i} onChange={e=>onChange({
                ...value,
                businessIndustry:e.target.checked?[...value.businessIndustry, i]:value.businessIndustry.filter(bi=>bi!==i)
                })}/>
            <label htmlFor={i}>{lang[i]||i}</label><br/>
          </div>)}
          <div className="large-6 columns">
            <input type="checkbox" name="company_industry" id="industry_other" checked={value.otherIndustry!==undefined} onChange={e=>onChange({...value, otherIndustry:e.target.checked?"":undefined})}/>
            <label htmlFor="industry_other">{lang["Other"]||"Other"}</label>
          </div>
        </fieldset>
      </div>
    </div>
    {value.otherIndustry!==undefined && <div className="row">
      <div className="large-7 columns other_sector_fieldset" style1="display: none;">
        <label className="bold" htmlFor="other_sector" id="other_sector_title">{lang.tsOther} <Validator id="tsAdditionalInfo,: ,tsIndustry,tsOther" value={value.companySell}/></label>
          <input type="text" name="other_sector" id="other_sector" value={value.otherIndustry} size="20" onChange={e=>onChange({...value, otherIndustry:e.target.value})}/>        
      </div>      
    </div> }

    <div className="row">
      <div className="large-4 columns">
        <fieldset>
          <label className="bold" tabIndex="0">{lang.tsTotalRevenue}: <Validator id="tsAdditionalInfo,: ,tsTotalRevenue" value={value.totalRevenue}/></label>
          <div onChange={e=>onChange({...value, totalRevenue:e.target.value})}>
          {REVENUE_LEVELS.map(i=><React.Fragment key={i}>
            <label> <input type="radio" name="annual_revenues" value={i} id={i}/>{lang[i]||i}</label>
          </React.Fragment>)}
          </div>
        </fieldset>
      </div>
      <div className="large-4 columns">
        <fieldset>
          <label className="bold" tabIndex="0">{lang.tsI11l} <Validator id="tsAdditionalInfo,: ,tsI11l" value={value.currentSellingInt}/></label>
          <div onChange={e=>onChange({...value, currentSellingInt: e.target.value})}>
            <label> <input type="radio" name="company_export" value="Yes" id="company_export_Yes"/> {lang.yes}</label>
            <label> <input type="radio" name="company_export" value="No" id="company_export_No"/> {lang.no}</label>            
            <label> <input type="radio" name="company_export" value="Unsure" id="company_export_Unsure"/> {lang.unsure}</label>        
          </div>
        </fieldset>
      </div>      
      <div className="large-4 columns">
        {value.currentSellingInt==="Yes" && <fieldset id="export_percent_set" style1="display: none;">
          <label className="bold" tabIndex="0">{lang.tsI11lPct} <Validator id="tsAdditionalInfo,: ,tsI11lPct" value={value.percentSellInt}/></label>
          <div onChange={e=>onChange({...value, percentSellInt:e.target.value})}>
            {REVENUE_I11L_PERCENT.map(i=><React.Fragment key={i}>
              <label> <input type="radio" name="export_percentage" value={i} id={i}/> {lang[i]||i}</label>
            </React.Fragment>)}
          </div>
        </fieldset>}
      </div>
    </div>
    {value.currentSellingInt==="Yes" && <div className="row">
      <div className="large-12 columns">
        <fieldset>
          <label className="bold" tabIndex="0">{lang.tsCountriesSell} <Validator id="tsAdditionalInfo,: ,tsCountriesSell" value={value.sellcountry.length}/></label>
          <div className="border" style={{overflowY:"scroll", height:"200px"}}>
          {COUNTRIES.map(i=><div key={i} className="large-6 columns">
            <input type="checkbox" name="export_countries" checked={value.sellcountry.includes(i)}
              value={i} id={i} onChange={e=>onChange({
                ...value,
                sellcountry:e.target.checked?[...value.sellcountry, i]:value.sellcountry.filter(ci=>ci!==i)
                })}/>
            <label htmlFor={i}>{lang[i]||i}</label><br/>
          </div>)}
          </div>
        </fieldset>
      </div>      
    </div>}

    <fieldset>
      <div className="row multi_checkbox">
        <div className="large-7 columns">
          <label className="bold" tabIndex="0">{lang.tsCompanyGoal} <Validator id="tsAdditionalInfo,: ,tsCompanyGoal" value={value.otherObject??value.regObjective.length}/></label>
          {COMPANY_GOALS.map(i=><React.Fragment key={i} >
            <input type="checkbox" name="company_goal" checked={value.regObjective.includes(i)}
              value={i} id={i} onChange={e=>onChange({
                ...value,
                regObjective:e.target.checked?[...value.regObjective, i]:value.regObjective.filter(ci=>ci!==i)
                })}/>
            <label htmlFor={i}>{lang[i]||i}</label><br/>
          </React.Fragment>)}
          <input type="checkbox" name="company_goal" id="company_goal_other" checked={value.otherObject!==undefined} onChange={e=>onChange({...value, otherObject:e.target.checked?"":undefined})}/>
          <label htmlFor="company_goal_other">{lang["Other"]||"Other"}</label>
        </div>
      </div>
      {value.otherObject!==undefined && <div className="row">
        <div className="large-7 columns company_goal_other_fieldset" style1="display: none;">
          <label className="bold" htmlFor="other_company_goal" id="other_company_goal_title">{lang.tsOther} <Validator id="tsAdditionalInfo,: ,tsCompanyGoal,tsOther" value={value.otherObject}/></label>
            <input type="text" name="other_company_goal" id="other_company_goal" value={value.otherObject} size="20" onChange={e=>onChange({...value, otherObject:e.target.value})}/>
        </div>                
      </div>}
    </fieldset>
    <div className="row" id="company_expectations">
      <div className="large-12 columns">
        <label className="bold" htmlFor="company_expectations" id="company_expectations_title" tabIndex="0">{lang.tsCompanyExpectations} <Validator id="tsAdditionalInfo,: ,tsvCompanyExpectations" value={value.typeBusinessDesc}/></label>
        <textarea style={{margin:"0"}} name="company_expectations" id="company_expectations" value={value.typeBusinessDesc} rows="5" cols="50" onChange={e=>onChange({...value, typeBusinessDesc:e.target.value.slice(0, 1000)})}></textarea>
        <div style={{marginBottom:"1rem"}} className="text-right">{lang.max1000c}</div>          </div>
    </div>
    <div className="row" id="company_profile_wrapper">
      <div className="large-12 columns">
        <label className="bold" htmlFor="company_profile" id="company_profile_title" tabIndex="0">{lang.tsCompanyProfile} <Validator id="tsAdditionalInfo,: ,tsvCompanyProfile" value={value.companyProfile}/></label>
        <textarea style={{margin:"0"}} name="company_profile" id="company_profile" rows="5" cols="50" value={value.companyProfile} onChange={e=>onChange({...value, companyProfile:e.target.value.slice(0, 1000)})}></textarea>
        <div style={{marginBottom:"1rem"}} className="text-right">{lang.max1000c}</div>          </div>
    </div>
    {onContinue && <div className="text-right"><button className="button" style={{ display: "inline-block", margin: "0" }} onClick={onContinue}>{lang.continue}</button></div>}
  </FieldsBox>
}

        //<div className="words">Word count: <span className="bold" id="display_count">0</span> Words left: <span className="bold" id="word_left">150</span>            </div>