import styled from 'styled-components';

export const FieldsBox = styled.fieldset`
  padding:1rem;
  margin:0.5rem;
  background: #f9f9f9;
  border: 1px solid #cacaca;
  & legend {
    font-size:1.5rem;
  }
`
