import React, { useContext } from 'react';
import { I18NContext } from "./Contexts";
import { FieldsBox } from "./FieldsBox";

export const EventInfo = props => {
  const lang = useContext(I18NContext);
  const { event, onContinue } = props;
  return <FieldsBox>
    <div className="row">
      <div className="large-10 columns">
        <legend tabIndex="0">{(lang.lang === "fr" ? event.eventnamefr : event.eventname) + (event.eventloc ? " - " + event.eventloc : "")}</legend>
      </div>
      {event.eventloc && <div className="large-2 columns">
        <div style={{ margin: "0.5rem" }} tabIndex="0">
          <strong><span className="map_link">
            <a href={"https://maps.google.com/?q=" + event.eventloc} target="_blank" rel="noopener noreferrer"><i className="fa fa-map-marked-alt"></i> {lang.mapIt}</a>
          </span></strong>
        </div>
      </div>}
    </div>
    <div className="row">
      <div className={(event.onestepreg === "Y"?"large-9":"large-12")+" columns"}>
        <label tabIndex="0">{lang.eventDate}</label>
        <div className="border-box" tabIndex="0">{event.eventdate}</div>
      </div>
      {event.onestepreg === "Y"&&<div className="large-3 columns">
        <label tabIndex="0">{event.flexbilefee === "True"?lang.eventRegisterFee_flexible:lang.eventRegisterFee}</label>
        <div className="border-box" tabIndex="0">{"$" + event.eventfee}</div>
      </div>}
    </div>
    <div className="row">
      <div className="large-6 columns">
        <label tabIndex="0">{lang.eventOrgEmail}</label>
        <div className="border-box" tabIndex="0">{event.orgemail}</div>
      </div>
      <div className="large-6 columns">
        <label tabIndex="0">{lang.eventOrgPhone}</label>
        <div className="border-box" tabIndex="0">{event.eventhelp}</div>
      </div>
    </div>
    <div className="row">
      <div className="large-12 columns">
        <label tabIndex="0">{lang.eventDescription}</label>
        <div className="border-box" tabIndex="0">
          <pre>{lang.lang === "fr" ? event.eventdespfr : event.eventdesp}</pre>
        </div>
      </div>
    </div>
    {onContinue && <div className="text-right"><button className="button" style={{ display: "inline-block", margin: "0" }} onClick={onContinue}>{event.onestepreg==="Y"?lang.registerNow:lang.form2Continue}</button></div>}
  </FieldsBox>;
}
