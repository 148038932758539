export const html=`<p align="center">
<strong>
    Ministère du Développement économique, de la Création d’emplois et du
    Commerce
</strong>
<strong></strong>
</p>
<p align="center">
<strong>
    Mission dirigée par le personnel de la Direction de la croissance
    commerciale à l’étranger à [state location, and start date to end date]
</strong>
<strong></strong>
</p>
<p align="center">
<strong>ENTENTE DE PARTICIPATION À LA MISSION</strong>
<strong></strong>
</p>
<p align="center">
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>L’ENTENTE </strong>
entre en vigueur le [insert date] 
</p>
<p>
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>
    ENTRE : &ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;Sa Majesté le Roi du chef de l’Ontario, représentée par le
    ministre du Développement économique, de la Création d’emplois et du
    Commerce (le « ministère »)
</strong>
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>
    ET : &ensp;&ensp;&ensp;[Insert full legal name of the Company] (le «
    délégué »)
</strong>
<strong></strong>
</p>
<p>
<strong>ATTENDU QUE</strong>
le ministère dirige une mission à <strong>[state location, and start date to end date]</strong> (« <strong>mission</strong> ») et que le délégué a été autorisé sous
condition à participer à cette mission;<strong></strong>
</p>
<p>
<strong>ATTENDU QUE</strong>
, comme condition de sa participation à la mission et avant la fin de son
inscription, le délégué doit lire et accepter la présente entente ainsi que
la signer par voie électronique, y compris l’annexe A (formulaire
d’exonération de responsabilité et d’indemnisation) («
<strong>
    formulaire d’exonération de responsabilité et d’indemnisation
</strong>
») pour attester de son acceptation des conditions de cette participation;
</p>
<p>
<strong></strong>
</p>
<p>
<strong>POUR CES MOTIFS</strong>
, compte tenu des engagements mutuels et conventions prévus dans la
présente entente et de toute autre contrepartie de valeur, dont la
réception et la convenance sont expressément reconnues, Sa Majesté la Reine
du chef de l’Ontario, représentée par le ministère, et le délégué
(conjointement avec le ministère, les « <strong>parties</strong> »)
conviennent de ce qui suit :
</p>
<p>
<strong>1. Entente – Dispositions générales. </strong>
</p>
<p>
a) L’entente et le formulaire d’exonération de responsabilité et
d’indemnisation constituent l’entente intégrale entre les parties en ce qui
concerne son objet et remplacent toutes les déclarations et ententes orales
ou écrites antérieures;
</p>
<p>
b) Le ministère peut modifier l’entente en tout temps sur remise d’un avis
au délégué. Un avis de modification sera alors envoyé au délégué par
courriel, dès que raisonnablement possible. Une fois que l’avis est publié
sur une telle page Web, il sera réputé avoir été communiqué au délégué.
Après avoir reçu l’avis, le délégué doit fournir une confirmation écrite au
ministère dans les cinq jours ouvrables dans laquelle il stipule qu’il
accepte ou refuse les modifications. Si le délégué n’accepte pas les
modifications ou ne fournit pas une réponse écrite au ministère dans les
cinq jours ouvrables suivant l’émission de l’avis, il doit immédiatement se
retirer de la mission;
</p>
<p>
c) L’invalidité ou le caractère non exécutoire d’une disposition quelconque
de l’entente n’a aucune incidence sur la validité ou le caractère
exécutoire de toute autre disposition de l’entente.
</p>
<p>
<strong>2. Date d’entrée en vigueur.</strong>
L’entente entre en vigueur à la date indiquée dans la partie supérieure de
l’entente et, sous réserve de l’article 10 de l’entente, expirera le <strong>[insert eventend]</strong>
, sauf si elle est résiliée avant conformément à l’article 9 de l’entente.
</p>
<p>
<strong>3. LAIPVP et renseignements de tiers.</strong>
Le gouvernement de l’Ontario est lié par la    <em>Loi sur l’accès à l’information et la protection de la vie privée</em>,
L.R.O. 1990, chap. F.31 (« <strong>LAIPVP</strong> »), et ses
modifications, et tout renseignement fourni à l’Ontario, y compris au
ministère, pourrait être divulgué conformément aux exigences de cette loi.
L’article 17 de la LAIPVP prévoit une exemption à l’égard de la divulgation
de certains renseignements de tiers qui révèlent un secret industriel ou
des renseignements d’ordre scientifique, technique, commercial, financier
ou ayant trait aux relations de travail, fournis à titre confidentiel, s’il
est raisonnable de s’attendre à ce que la divulgation de ces renseignements
ait pour effet de causer certains préjudices. Le délégué convient que les
secrets industriels ou les renseignements d’ordre scientifique, technique,
commercial, financier ou ayant trait aux relations de travail à caractère
confidentiel que le délégué soumet au ministère seront clairement indiqués
comme tels par le délégué. Le délégué reconnaît que le ministère, avant
d’accorder l’accès à un dossier qui pourrait contenir des renseignements
visés par l’article 17 de la LAIPVP, avisera le délégué concerné pour qu’il
puisse faire des déclarations au ministère relativement à la divulgation de
ces renseignements.
</p>
<p>
<strong>4. Déclarations, garanties et engagements du délégué.</strong>
Le délégué déclare et garantit ce qui suit au ministère et s’y engage
auprès de celui-ci :
</p>
<p>
a) Il est, et continuera d’être, une personne morale légalement constituée
ayant pleins pouvoirs pour s’acquitter de ses obligations aux termes de
l’entente;
</p>
<p>
b) Il i) a le plein pouvoir et la pleine capacité de conclure l’entente et
ii) a pris les mesures nécessaires pour autoriser la signature de
l’entente;
</p>
<p>
c) Il se conforme, et continuera de se conformer, à l’ensemble des lois et
règlements fédéraux et provinciaux canadiens et des règlements municipaux,
ainsi qu’aux autres ordonnances, règles et règlements se rapportant à tout
aspect de l’entente, de la mission ou des deux;
</p>
<p>
d) Ses documents, comme ils sont définis ci-après, et tout autre
renseignement qu’il a fourni au ministère concernant un aspect de
l’entente, de la mission ou des deux étaient véridiques et complets au
moment où il les a fournis et demeureront véridiques et complets;
</p>
<p>
e) Il ne cédera aucun de ses droits ni aucune de ses obligations aux termes
de l’entente. Dans l’éventualité où le délégué ne peut pas assister à la
mission, il doit aviser le ministère par écrit de son annulation dès que
raisonnablement possible et sans retard injustifié avant la date de début
de la mission;<strong><em></em></strong>
</p>
<p>
<strong><em></em></strong>
</p>
<p>
f) Les employés du délégué, ses fonctionnaires, ses mandataires ou les
invités qui peuvent l’accompagner (collectivement, le « <strong>personnel du délégué</strong> ») auront chacun un passeport valide
pour la durée de la mission;
</p>
<p>
g) Il i) assistera à tous les programmes officiels requis de la mission;
ii) remplira un formulaire sur la satisfaction à l’égard de l’évaluation de
la mission à la fin de la mission; et iii) remettra un résumé de ses
résultats de la mission en tant que délégué (par exemple, les ventes, les
contrats, les protocoles d’entente ou les coentreprises auxquels il a
participé) au ministère à la fin de la mission
, puis à des intervalles de 6, 12 et 18 mois (collectivement appelés les «
<strong>résultats de la mission du délégué</strong>
»). Le délégué reconnaît que les renseignements recueillis par le ministère
conformément au paragraphe 4 f) seront traités comme étant confidentiels et
assujettis aux obligations en vertu de la LAIPVP du gouvernement de
l’Ontario et seront uniquement utilisés pour faire état au ministère des
résultats agrégés de tous les participants à la mission;
</p>
<p>
h) Il sera responsable des coûts qu’il engagera ou de ceux que son
personnel engagera, y compris les frais liés au tarif aérien, au transport
à destination et en provenance des aéroports au début et à la fin de la
mission, à l’hébergement, aux repas, aux frais accessoires, aux besoins de
son représentant ou de son personnel, et aux activités de participation du
public ou aux efforts promotionnels dont est responsable le délégué;
</p>
<p>
i) À la demande raisonnable du ministère, il lui fournira certains
documents pour l’aider dans l’administration et la promotion de la mission
et les communications à son sujet (les « <strong>documents</strong> »);
</p>
<p>
j) Dans la mesure où les documents contiennent, en tout ou en partie, la
propriété intellectuelle du délégué ou d’un tiers, il accorde au ministère
un droit et une licence perpétuels, mondiaux, non exclusifs, irrévocables,
transférables, exempts de redevance et entièrement payés i) permettant
d’utiliser, de modifier, de reproduire, de publier et de distribuer, sous
quelque forme que ce soit, les documents; et ii) autorisant d’autres
personnes à utiliser, modifier, reproduire, publier et distribuer, sous
quelque forme que ce soit, les documents au nom du ministère afin de
promouvoir la mission ou de communiquer à son égard. Le délégué renonce
irrévocablement, en faveur du ministère, à tous les droits d’intégrité et
aux autres droits moraux relatifs aux documents, pour toujours;
</p>
<p>
k) Nonobstant le paragraphe 4 j), les documents ne constitueront pas une
violation des droits de propriété intellectuelle de tiers et n’entraîneront
pas la violation de ces droits;
</p>
<p>
l) Il renoncera à tout droit d’inspecter ou d’approuver les communications
et les communiqués de presse finis et dégagera de toute responsabilité Sa
Majesté la Reine du chef de l’Ontario, y compris ses ministres,
mandataires, personnes nommées et employés (les «    <strong>parties indemnisées</strong> ») à l’égard de toute poursuite,
réclamation et demande découlant de l’utilisation, de la modification, de
la reproduction, de la publication et de la distribution d’un document et
de toute utilisation ou mauvaise utilisation, y compris, sans s’y limiter,
toute altération, modification, réutilisation ou falsification des
documents pouvant survenir ou être causée par des tiers, y compris les
médias;
</p>
<p>
m) Il n’utilisera aucun insigne ni logo de Sa Majesté le Roi du chef de
l’Ontario, sauf s’il a reçu le consentement préalable écrit du ministère à
cet égard.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>5. Déclarations, garanties et engagements du ministère.</strong>
Le délégué reconnaît que :
</p>
<p>
a)
    Aux fins de la mission, le ministère facturera des frais d’inscription
au délégué d’un montant de <strong>[insert fee amount]</strong> $ CA («<strong>frais d’inscription</strong> »). Le délégué paiera le montant
    total des frais d’inscription un jour ouvrable après que le délégué a
    confirmé au ministère la réception du lien de paiement par carte de
    crédit (aussi appelé « CCPay ») envoyé par le ministère. Le délégué
    disposera de cinq jours ouvrables à partir de la date du paiement
    complet des frais d’inscription pour être remboursé en totalité. En
    dehors de la période indiquée, le ministère peut, à sa seule
    discrétion, rembourser intégralement les frais d’inscription du
    délégué;
</p>
<p>
b) Dans le cadre des frais d’inscription à facturer au délégué conformément
au paragraphe 5 a), le ministère fournira au délégué les activités
indiquées dans le programme officiel de la mission, y compris les activités
qui ont lieu avant et pendant la mission;
<s>
</s>
</p>
<p>
c) Si le délégué le demande, le ministère fournira une lettre de
confirmation de participation à la mission au délégué et à son personnel,
selon le cas, à l’égard de la mission dans le but d’obtenir un ou plusieurs
visas pour tous les pays faisant l’objet de la mission. Il incombe au
délégué d’obtenir tous les visas requis et en assumer les frais, y compris
ceux pour son personnel. À la demande raisonnable du délégué, le ministère
fournira des renseignements supplémentaires pouvant être requis.
</p>
<p>
<strong><em></em></strong>
</p>
<p>
<strong>6. Respect des lois étrangères.</strong>
Le délégué reconnaît que, pour la durée de sa participation à la mission,
lui et son personnel doivent se conformer à toutes les lois et
réglementations nationales, infranationales et locales, ainsi qu’à toute
autre ordonnance, règles et règlements administratifs des pays dans
lesquels se déroule la mission.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>7.</strong>
<strong>Assurance et indemnisation. </strong>
Le délégué déclare et garantit ce qui suit et s’y engage :
</p>
<p>
a) Il a souscrit et maintient en vigueur, à ses propres frais, auprès
d’assureurs ayant obtenu, une cote d’au moins B+ de la société A.M. Best ou
l’équivalent, toute l’assurance nécessaire et appropriée que souscrirait
une personne prudente qui entreprend l’activité du délégué, y compris une
assurance de responsabilité civile des entreprises d’au moins 2 000 000 $
par évènement pour les blessures corporelles, les préjudices physiques et
les dommages matériels subis par des tiers. La police d’assurance de
responsabilité civile des entreprises comprendra ce qui suit : (i) les
parties indemnisées en tant qu’assurés supplémentaires à l’égard de la
responsabilité civile qui découle de l’exécution des obligations du délégué
aux termes de l’entente ou de la participation à la mission, ou
relativement à celle-ci; (ii) une clause de responsabilité réciproque;
(iii) une protection contre la responsabilité contractuelle et (iv) un avis
d’annulation écrit de trente (30) jours;
</p>
<p>
b) Le délégué indemnisera les parties indemnisées contre les i) causes
d’action, obligations, pertes, coûts, dommages et dépenses (y compris les
honoraires d’avocats, d’experts et de consultants) que toute personne
pourrait subir ou engager et qui découleraient de la participation du
délégué ou du personnel du délégué à la mission ou à toute partie de
l’entente; et les ii) actions, réclamations, demandes, poursuites et autres
instances judiciaires que toute personne fait, présente ou intente et qui
découleraient de la participation du délégué ou du personnel du délégué à
la mission ou à toute partie de l’entente, à moins qu’ils ne soient causés
uniquement par la négligence ou une inconduite volontaire des parties
indemnisées;
</p>
<p>
c) En plus de ce qui précède, le délégué enverra au ministère un formulaire
d’exonération de responsabilité et d’indemnisation dûment signé qui se
trouve ici Voir l’annexe A (formulaire d’exonération de responsabilité et d’indemnisation) avant la
fin de son inscription. Le formulaire d’exonération de responsabilité et
d’indemnisation dûment signé sera envoyé au ministère en même temps que
l’entente dûment signée.
</p>
<p>
<strong>8. Indépendance des parties.</strong>
Le délégué ou tout personnel du délégué n’est pas le mandataire, le
coentrepreneur, le partenaire ou l’employé du ministère; il ne se présente
pas d’une façon pouvant suggérer à une personne raisonnable une telle
relation et ne prend aucune mesure qui puisse établir ou laisser supposer
une telle relation.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>
    9. Résiliation sur remise d’un avis et annulation de la mission.
</strong>
Le délégué reconnaît que :
</p>
<p>
a) Le ministère peut résilier l’entente en tout temps, sans responsabilité,
frais ou pénalité, sur remise d’un avis au délégué;
</p>
<p>
b) Si le ministère annule la mission pour des raisons qui relèvent de son
contrôle, il remboursera les coûts raisonnables liés à la mission (sans
intérêt) engagés et payés par le délégué, pourvu que le délégué (i)
produise des copies fidèles et complètes des factures et (ou) des reçus
justifiant de tels coûts et (ii) conserve les copies originales des
factures et (ou) des reçus justifiant de tels coûts qui peuvent être
examinés par le ministère;
</p>
<p>
c) Dans le cas où le ministère annule la mission conformément au paragraphe
(9) b), le délégué fera de son mieux pour minimiser ou réduire les coûts de
la mission déjà engagés, qu’il prévoit engager et qu’il peut engager;
</p>
<p>
d) Le ministère tiendra compte de la    <em>Directive sur les frais de déplacement, de repas et d’accueil</em>, et
ses modifications <a href="https://www.ontario.ca/fr/page/directive-sur-les-frais-de-deplacement-de-repas-et-daccueil-2020"> 
https://www.ontario.ca/fr/page/directive-sur-les-frais-de-deplacement-de-repas-et-daccueil-2020</a> afin de déterminer
les coûts raisonnables qu’il peut rembourser en vertu du paragraphe (9) b);
</p>
<p>
e) Nonobstant le paragraphe 9 b), si des évènements imprévus hors du
contrôle du ministère, y compris ceux liés aux actions de tiers, entraînent
le report ou l’annulation de la mission, le ministère remboursera (sans
intérêt) uniquement les frais d’inscription payés par le délégué, comme il
est décrit au paragraphe 5 a) de l’entente;
</p>
<p>
f) Nonobstant les paragraphes 9 b) et e), le ministère n’assumera aucun
autre coût, responsabilité ou pénalité.
</p>
<p>
<strong>10.</strong>
<strong>Maintien en vigueur.</strong>
</p>
<p>
a) Les paragraphes 4 g), j), k), l), m) et n) de l’article 7 et le
paragraphe 10 a) de l’entente, ainsi que le formulaire d’exonération de
responsabilité et d’indemnisation demeureront en vigueur après la
résiliation, l’annulation ou l’expiration de l’entente;
</p>
<p>
b) Les paragraphes 1 a) et c) et 4 a), les articles 8, 9, le paragraphe 10
b), et l’article 11 de l’entente, ainsi que toutes les définitions
applicables de l’entente, resteront en vigueur pendant une période de sept
ans à compter de la date de résiliation ou d’expiration de l’entente.
</p>
<p>
<strong>11. Lois applicables.</strong>
L’entente, les droits et obligations des parties et les relations entre les
parties seront régis par les lois de la province de l’Ontario et les lois
fédérales applicables et seront interprétés en conséquence. Toute action ou
instance résultant de l’entente ou de la mission sera conduite devant les
tribunaux de l’Ontario qui auront compétence exclusive en la matière.
</p>
<p>
<strong>12. Signature, confirmation et transmission. </strong>
Le délégué reconnaît ce qui suit et en convient :<strong></strong>
</p>
<p>
a) Les parties conviennent que le délégué peut valablement signer l’entente
et le formulaire d’exonération de responsabilité et d’indemnisation par
voie électronique et que la signature électronique du délégué est
l’équivalent légal d’une signature manuscrite. La signature électronique du
délégué peut être attestée comme suit : i) une signature numérique
comprenant le nom du signataire autorisé inscrit à la ligne de signature
respective de l’entente et du formulaire d’exonération de responsabilité et
d’indemnisation ou tout autre moyen avec le
consentement écrit préalable du ministère; et ii) l’attente et le
formulaire de renonciation, exonération et d’indemnité remis par courriel
au ministère par le délégué;
<p>
b) À la suite de la signature électronique de l’entente et du formulaire
d’exonération de responsabilité et d’indemnisation par le délégué, le
délégué soussigné enverra un courriel de confirmation au ministère à partir
d’un compte de courriel vérifiable indiquant i) qu’il a dûment signé
l’entente et le formulaire d’exonération de responsabilité et
d’indemnisation joints à l’annexe A de l’entente; ii) qu’il est dûment
autorisé à lier le délégué par sa signature; et iii) qu’il fournira la date
et l’heure de la signature du délégué;
</p>
<p>
c) Le délégué devra renvoyer l’entente et le formulaire d’exonération de
responsabilité et d’indemnisation dûment signés au ministère en même temps
et dans le même courriel.
</p>
<p>
<strong>
    Par la présente, le délégué soussigné reconnaît avoir lu et compris les
    conditions de l’entente, y compris le formulaire d’exonération de
    responsabilité et d’indemnisation joint à l’annexe A de l’entente, et
    en apposant sa signature électronique à l’entente, y compris le
    formulaire d’exonération de responsabilité et d’indemnisation joint à
    l’annexe A de la présente entente, il accepte d’être lié par toutes les
    conditions qui y sont énoncées.
</strong>
<strong></strong>
</p>
<p></p>
<p></p>


<p align="center">
<strong>
    Ministère du Développement économique, de la Création d’emplois et du
    Commerce
</strong>
<strong></strong>
</p>
<p align="center">
<strong>
    Mission dirigée par le personnel de la Direction de la croissance
    commerciale à l’étranger à [state location, and start date to end date] </strong>
<strong></strong>
</p>
<p align="center">
<strong>ANNEXE A DE</strong>
<strong></strong>
</p>
<p align="center">
<strong>
    L’ENTENTE DE PARTICIPATION À LA MISSION en vigueur le [insert date]
    (« l’entente »)
</strong>
<strong></strong>
</p>
<p align="center">
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>
    ENTRE : &ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;Sa Majesté le Roi du chef de l’Ontario, représentée par le
    ministre du Développement économique, de la Création d’emplois et du
    Commerce (le « ministère »)
</strong>
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>
    ET : &ensp;&ensp;&ensp;[Insert full legal name of the Company] (le «
    délégué »)
</strong>
<strong></strong>
</p>
<p align="center">
<strong></strong>
</p>
<p align="center">
<strong></strong>
</p>
<p align="center">
<strong>
    <u>FORMULAIRE D’EXONÉRATION DE RESPONSABILITÉ ET D’INDEMNISATION</u>
</strong>
<strong><u></u></strong>
</p>
<p align="center">
<strong></strong>
</p>
<p>
Le délégué est entièrement responsable des conséquences de sa participation
à la mission à <strong> [state location, and start date to end date] </strong>
 (« <strong>mission</strong> »). En contrepartie d’être accepté en tant
que participant à la mission, le délégué indemnisera Sa Majesté la Reine du
chef de l’Ontario, y compris ses ministres, mandataires, personnes nommées
et employés (les « <strong>parties indemnisées</strong> ») contre (i) les
causes d’action, obligations, pertes, coûts, dommages et dépenses (y
compris les honoraires d’avocats, d’experts et de consultants) que toute
personne pourrait subir ou engager et qui découleraient de la participation
du délégué ou de ses employés, ses fonctionnaires ou mandataires, ou des
invités qui l’accompagnent (collectivement le «    <strong>personnel du délégué</strong> ») à la mission ou à toute partie à
l’entente; et les ii) actions, réclamations, demandes, poursuites et autres
instances judiciaires que toute personne fait, présente ou intente et qui
découleraient de la participation du délégué ou du personnel du délégué à
la mission ou à toute partie à l’entente, à moins qu’ils ne soient causés
uniquement par la négligence ou une inconduite volontaire des parties
indemnisées.
<u>
    Le délégué reconnaît avoir avisé par écrit le personnel du délégué des
    modalités qui s’appliquent à sa situation figurant dans la présente.
</u>
</p>
<p>
Le délégué et le personnel du délégué, ainsi que leurs héritiers,
exécuteurs et administrateurs, déchargent les parties indemnisées de tous
les droits, réclamations, pertes, etc., mentionnés ci-dessus, peu importe
qu’ils soient liés ou non à la mission ou à toute partie de l’entente. Le
délégué accepte que sa responsabilité d’indemniser ou de rembourser les
parties indemnisées en vertu de la présente entente ne nuira pas ni ne
portera préjudice au droit des parties indemnisées de se prévaloir de tout
autre droit en vertu de la loi.<a name="_Hlk36569547"> </a>
<u>
    Le délégué reconnaît avoir avisé par écrit le personnel du délégué des
    modalités qui s’appliquent à sa situation figurant dans la présente.
</u>
</p>
<p>
Le délégué reconnaît que son personnel l’accompagnera ou participera à la
mission à leur propre risque, et qu’ils sont conscients qu’ils ne doivent
pas quitter le Canada sans obtenir d’abord une couverture d’assurance
appropriée pour les dépenses médicales, les accidents individuels, la perte
des bagages et le raccourcissement ou l’annulation du voyage, qu’il est de
leur entière responsabilité de s’assurer qu’ils souscrivent une assurance
adéquate, et que même avec une assurance, les personnes peuvent avoir à
payer un traitement médical sur-le-
champ, ainsi que d’autres éventualités, et qu’ils doivent ensuite demander
un remboursement à l’assureur, s’ils sont couverts. Le délégué et son
personnel reconnaissent que les parties indemnisées ne sont pas
responsables de s’assurer que le délégué ou son personnel ont souscrit une
assurance ou que cette assurance est appropriée, et que les parties
indemnisées ne seront en aucun cas responsables des dépenses ou des pertes
du délégué ou de son personnel, y compris, sans s’y limiter, une perte
couverte par une assurance médicale à l’extérieur du pays, un accident
individuel, une perte de bagages ou le raccourcissement ou l’annulation de
l’assurance voyage.
<u>
    Le délégué reconnaît avoir avisé par écrit le personnel du délégué des
    modalités qui s’appliquent à sa situation figurant dans la présente.
</u>
</p>
<p>
Le délégué et le personnel du délégué acceptent de
<a name="_Hlk36568092">
    dégager de toute responsabilité les parties indemnisées pour des
    préjudices, des pertes ou des dommages de toute sorte
</a>
<a name="_Hlk36568188">
    pouvant découler de i) l’organisation du voyage ou de l’hébergement en
    lien avec la mission ou de (ii) l’incapacité à organiser adéquatement
    le voyage ou l’hébergement en lien avec la mission.
</a>
<u>
    Le délégué reconnaît avoir avisé par écrit le personnel du délégué des
    modalités qui s’appliquent à sa situation figurant dans la présente.
</u>
</p>
<p>
<u>Signature, confirmation et transmission.</u>
<u></u>
</p>
<p>
Les parties conviennent que le délégué peut valablement signer le
formulaire d’exonération de responsabilité et d’indemnisation joint à
l’annexe A par voie électronique et que la signature électronique du
délégué est l’équivalent légal d’une signature manuscrite. La signature
électronique du délégué peut être attestée comme suit : i) une signature
numérique comprenant le nom du signataire autorisé inscrit à la ligne de
signature respective de l’entente et du formulaire d’exonération de
responsabilité et d’indemnisation ou tout autre moyen avec le consentement
écrit préalable du ministère; et ii) le formulaire d’exonération de
responsabilité et d’indemnisation remis par courriel au ministère par le
délégué.
</p>
<p>
À la suite de la signature électronique de l’entente et du formulaire
d’exonération de responsabilité et d’indemnisation par le délégué, le
délégué soussigné enverra un courriel de confirmation au ministère à partir
d’un compte de courriel vérifiable indiquant i) qu’il a dûment signé
l’entente, y compris le formulaire d’exonération de responsabilité et
d’indemnisation joint à l’annexe A de l’entente; ii) qu’il est dûment
autorisé à lier le délégué par sa signature; et iii) qu’il fournira la date
et l’heure de la signature du délégué.
</p>
<p>
Le délégué devra renvoyer l’entente et le formulaire d’exonération de
responsabilité et d’indemnisation dûment signés au ministère en même temps
et dans le même courriel.
</p>
<p>
<strong>
    Par la présente, le délégué soussigné reconnaît avoir lu et compris les
    conditions de l’entente, y compris le formulaire d’exonération de
    responsabilité et d’indemnisation joint à l’annexe A de l’entente, et
    en apposant sa signature électronique à l’entente, y compris le
    formulaire d’exonération de responsabilité et d’indemnisation joint à
    l’annexe A de la présente entente, il accepte d’être lié par toutes les
    conditions qui y sont énoncées.
</strong>
<strong></strong>
</p>`