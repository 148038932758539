export const ENV = {
  production: false,
  mock: true,
  API: {
   host: '',
   // tslint:disable-next-line: max-line-length
   event: 'https://ws.eventreg.mgcs.gov.on.ca/EventReg.svc/GetEventInfo',
   // tslint:disable-next-line: max-line-length
   searchCompany: 'https://ws.eventreg.mgcs.gov.on.ca/EventReg.svc/GetCompanyInfo',
   startPay:"https://ws.eventreg.mgcs.gov.on.ca/EventReg.svc/RegEventAndProcessPay",//"https://uat-ecrm-api.azurewebsites.net/EventReg.svc/StartCCPay",
   updateStatus:"https://ws.eventreg.mgcs.gov.on.ca//EventReg.svc/CCPayUpdateStatus",
   getCP:"https://ws.eventreg.mgcs.gov.on.ca//EventReg.svc/GetCountryProv"

   /*event: 'http://localhost:4536/EventReg.svc/GetEventInfo',
   // tslint:disable-next-line: max-line-length
   searchCompany: 'http://localhost:4536/EventReg.svc/GetCompanyInfo',
   startPay:"http://localhost:4536/EventReg.svc/RegEventAndProcessPay",//"https://uat-ecrm-api.azurewebsites.net/EventReg.svc/StartCCPay",
   updateStatus:"http://localhost:4536/EventReg.svc/CCPayUpdateStatus",
   getCP:"http://localhost:4536/EventReg.svc/GetCountryProv"*/
 }
};

const twoStepResp = {"aEvent":{"eventid":"539e15cf-49b7-415f-a263-328a8688cb8f","eventname":"two step with fee staff led event 5 per company - no flexible fee","eventstart":"March 18, 2021","eventend":"May 28, 2021","eventdesp":"two step with fee ","eventhelp":"(416) 314-3927","eventfee":"600.00","eventcap":"300","registeredcount":"24","eventseatsleft":276,"eventloc":"30 Duke St W,Kitchener,N1G 3S3","eventwebsite":"https://uat-ecrm-app.azurewebsites.net/?eventid=539E15CF-49B7-415F-A263-328A8688CB8F&lang=en","eventdespfr":"two step with fee ","eventnamefr":"fr two step with fee staff led event 5 per company - no flexible fee","eventwebsitefr":null,"eventtype":"Webinar","eventformat":"On site","eventstatus":null,"regenddate":null,"rest":"Microsoft.Xrm.Sdk.EntityReference","countdowndays":null,"setregenddate":"False","promotioncode":null,"flexbilefee":"False","onestepreg":"N","orgemail":"Sabrina.Hong.Tang@ontario.ca","orgbranch":"Ministry of Economic Development, Job Creation and Trade","orgmailaddress":"222 JARVIS STREET FLOOR 5\r\nTORONTO ON M7A 0B6\r\nCanada","bnrequired":"N","eventdate":"March 18, 2021-May 28, 2021","eventmaxregpercompany":5,"eventagreementtype":"Staff led","eventsector":"eCommerce > Adtech"},"aCompany":null,"attendees":null,"wholecost":0.0,"outreachcost":0.0,"pContact":null};
const oneStepResp = {"aEvent":{"eventid":"2a252132-e71c-46d8-9591-a145b7532e7b","eventname":"90 one step registration conference","eventstart":"April 03, 2021","eventend":"April 16, 2021","eventdesp":"one step 90 per attend, not flexible fee","eventhelp":"(416) 314-3927","eventfee":"90.00","eventcap":"1000","registeredcount":"47","eventseatsleft":953,"eventloc":"220 yonge st,toronto,M5B 2H1","eventwebsite":"https://uat-ecrm-app.azurewebsites.net/?eventid=2A252132-E71C-46D8-9591-A145B7532E7B&lang=en","eventdespfr":"one step 90 per attend, not flexible fee fr","eventnamefr":"fr 90 one step registration","eventwebsitefr":null,"eventtype":"conference","eventformat":"On site","eventstatus":null,"regenddate":null,"rest":"Microsoft.Xrm.Sdk.EntityReference","countdowndays":null,"setregenddate":"False","promotioncode":null,"flexbilefee":"False","onestepreg":"Y","orgemail":"Sabrina.Hong.Tang@ontario.ca","orgbranch":"Ministry of Economic Development, Job Creation and Trade","orgmailaddress":"222 JARVIS STREET FLOOR 5\r\nTORONTO ON M7A 0B6\r\nCanada","bnrequired":"Y","eventdate":"April 03, 2021-April 16, 2021","eventmaxregpercompany":3,"eventagreementtype":"","eventsector":"eCommerce > Adtech"},"aCompany":null,"attendees":null,"wholecost":0.0,"outreachcost":0.0,"pContact":null}
export const COUNTRIES=["Afghanistan","Albania", "Algeria","American Samoa","Andorra","Angola", "Anguilla", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan",
"Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "British Virgin Islands",
"Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China", "Christmas Island",
"Cocos (Keeling) Islands", "Colombia", "Comoros", "Democratic Republic of the Congo", "Congo, Republic of", "Cook Islands", "Costa Rica", "Ivory Coast", "Croatia", "Cuba", "Curacao", "Cyprus",
"Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands",
"Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French Southern Territories", "Gabon", "Gambia", "Gaza Strip", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland",
"Grenada", "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Holy See (Vatican City)", "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia",
"Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, Democratic People's Rep. (North Korea)",
"Korea, Republic of (South Korea)", "Kosovo", "Kuwait", "Kyrgyzstan", "Lao, People's Democratic Republic", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
"Luxembourg", "Macau", "Macedonia, Rep. of", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", "Mauritius", "Mayotte", "Mexico",
"Micronesia, Federal States of", "Moldova, Republic of", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Myanmar, Burma", "Namibia", "Nauru", "Nepal", "Netherlands",
"Netherlands Antilles", "New Caledonia", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands", "Norway", "Oman", "Pakistan", "Palau",
"Palestinian territories", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Pitcairn Island", "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion Island", "Romania",
"Russian Federation", "Rwanda", "Saint Barthelemy", "Saint Helena, Ascension, and Tristan Da Cunha", "Saint Kitts and Nevis", "Saint Lucia", "Saint Martin", "Saint Pierre and Miquelon",
"Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Sint Maarten", "Slovakia",
"Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria, Syrian Arab Republic", "Taiwan",
"Tajikistan", "Tanzania", "Thailand", "Tibet", "Timor-Leste&nbsp;(East Timor)", "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands",
"Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Virgin Islands (British)",
"Virgin Islands (U.S.)", "Wallis and Futuna Islands", "West Bank", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"];
export const COMPANY_SELL=[ "Service(s)", "Product(s)", "Both"];
export const INDUSTRIES = ["Aerospace","Agriculture and Food Processing","Business & Professional Services","Chemicals","Construction and Infrastructure","Cultural Industries",
  "Defense & Security","Digital Media","Energy & Fuels (incl. Smart Grid)","Energy, Renewable","Environment","Financial Services","Forestry","Information and Communication Technologies",
  "Life Sciences","Manufacturing, General","Mining","Motor Vehicles","Primary Metals","Retailers & Wholesalers","Transportation Equipment & Services"];
export const REVENUE_LEVELS = [ "No net revenue (pre-commercial)", "Less than $100,000", "$100,000 ~ $999,999", "$1 million ~ $10 million", "$10 million ~ $50 million", "$50 million ~ $100 million", "More than $100 million" ];
export const REVENUE_I11L_PERCENT = [ "Less than 10%", "10-30%", "30-50%", "Greater than 50%"];
export const COMPANY_GOALS=[ "Sell your company's products/services to a new customer", "Seek a representative(s), distributor(s) or trade partner(s)",
  "Identify investment partners", "Identify or develop research collaborations", "Identify partners for joint venture(s) or technology licensing and transfer", "Identify partners for manufacturing",
  "Market research/competitor research", "Meet existing representative(s) or customer(s)", "Meet with government, regulatory and/or industry associations"];

export const apiFetch = async (url, params)=>{
  //const url = new URL(environment.API.event);
  //url.search = new URLSearchParams().toString();
  const result = await fetch(url, {
    method:'POST',
    body:JSON.stringify(params),//{eventid:'db7e90ca-94ab-4d2e-8555-702a41b53b68'}
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  return await result.json();
}

/*export const INTEREST_RESP = {
   "mess":"Registration Successfully",
   "mess_fr":null,
   "status":"OK",
   "regRequet":{
      "ccpayrequest":{
         "command":"INIT_PAYMENT",
         "payamount":"50",
         "urlOnCancel":"http://localhost:3000?code=c?tid=0F055aqdL1",
         "urlOnSuccess":"http://localhost:3000?code=s?tid=0F055aqdL1",
         "lang":"en",
         "proddes":"event name",
         "proddesfr":"event name fr",
         "promotioncode":null,
         "pdasigned":""
      },
      "attendees":[
         {
            "wid":"",
            "title":"Dr.",
            "firstname":"a",
            "lastname":"b",
            "phone":"d",
            "email":"c"
         },
         {
            "wid":"",
            "title":"Dr.",
            "firstname":"a",
            "lastname":"b",
            "phone":"d",
            "email":"c"
         }
      ],
      "eventid":"db7e90ca-94ab-4d2e-8555-702a41b53b68",
      "accountname":"ibm",
      "companyaddress1":"xxx",
      "companyaddress2":"",
      "companycity":"toronto",
      "companyprov":null,
      "companyzip":null,
      "companycountry":"Canada",
      "contactfirstname":null,
      "contactlastname":null,
      "contactemail":null,
      "participanttype":"attendee",
      "accountbn":null,
      "accountorgtype":null
   },
   "ccpayResp":{
      "redirectURl":"https://stage.sus.gov.on.ca/payment/action/edit?PAY_SESSION_ID=NzU1PzMlMQ..&encoded_session_id=NzU1PzMlMQ..&status=SUCCESS&pay_session_id=1005382&",
      "pay_session_id":"1005382",
      "internalpayid":"0F055aqdL1"
   }
}*/
/*
{
   "mess":"Registration Successfully",
   "mess_fr":null,
   "status":"OK",
   "regRequet":{
      "ccpayrequest":{
         "command":"INIT_PAYMENT",
         "payamount":"50",
         "urlOnCancel":"http://localhost:3000?code=c?tid=0F055aqdL1",
         "urlOnSuccess":"http://localhost:3000?code=s?tid=0F055aqdL1",
         "lang":"en",
         "proddes":"event name",
         "proddesfr":"event name fr",
         "promotioncode":null,
         "pdasigned":""
      },
      "attendees":[
         {
            "wid":"",
            "title":"Dr.",
            "firstname":"a",
            "lastname":"b",
            "phone":"d",
            "email":"c"
         }
      ],
      "eventid":"db7e90ca-94ab-4d2e-8555-702a41b53b68",
      "accountname":"ibm",
      "companyaddress1":"xxx",
      "companyaddress2":"",
      "companycity":"toronto",
      "companyprov":null,
      "companyzip":null,
      "companycountry":"Canada",
      "contactfirstname":null,
      "contactlastname":null,
      "contactemail":null,
      "participanttype":"attendee",
      "accountbn":null,
      "accountorgtype":null
   },
   "ccpayResp":{
      "redirectURl":"https://stage.sus.gov.on.ca/payment/action/edit?PAY_SESSION_ID=NzU1PzMlMQ..&encoded_session_id=NzU1PzMlMQ..&status=SUCCESS&pay_session_id=1005382&",
      "pay_session_id":"1005382",
      "internalpayid":"0F055aqdL1"
   }
}
{
   "errorcode":"00E",
   "errormessage":"Object reference not set to an instance of an object.",
   "errormessage_fr":null
}
*/

