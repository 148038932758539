import React, {useContext} from 'react';
import { FieldsBox } from "./FieldsBox";
import { Attendees } from "./Attendees";
import { Checkout } from "./Checkout";
import { SavedCompany, SearchCompany, CompanyInfo } from "./CompanyInfo";
import { ErrorSection} from "./ErrorSection";
import { I18NContext } from "./Contexts";

export const OneStepForm = props=>{
  const lang = useContext(I18NContext);
  const {
    savedCompany, useSavedCompany, confirmCompany, selectedCompany, onSelectCompany, searchResult, setSearchResult, searchText, setSearchText,
    company,
    errorSectionErrors,
    addCompany,
    event,
    onAddCompanyChange,
    cp,
    savedAttendee, setAttendees, attendees,
    setPayParams, payParams,
    processing, vErrors, setShowValidator, setErrorSectionErrors, setShowAgreement, preparePay, query
  } = props;
  return <>
              {savedCompany && <SavedCompany company={savedCompany} useSaved={useSavedCompany} confirm={savedAttendee ? () => { } : confirmCompany} />}
              {(!savedAttendee && (!savedCompany || useSavedCompany === false))
                && <SearchCompany selectedCompany={selectedCompany} onSelectedCompany={onSelectCompany} {...{ searchResult, setSearchResult, searchText, setSearchText }} />}
              {company && errorSectionErrors && <ErrorSection errors={errorSectionErrors}/>}
              {addCompany && <CompanyInfo company={addCompany} bnrequired={event.bnrequired} onChange={onAddCompanyChange} cp={cp} />}
              {company && <>
                <Attendees attendees={attendees} seatsLeft={event.eventseatsleft} setAttendees={savedAttendee ? () => { } : as => {
                  setAttendees(as);
                  if(event.flexbilefee!=="True")
                    setPayParams({ ...payParams, payamount: as.length * +event.eventfee }); 
                  }} />
                <Checkout payParams={payParams} setPayParams={setPayParams} flexible={event.flexbilefee === "True"} event={event} />
                <FieldsBox>
                  <div className="row" tabIndex="0">
                    <div className="large-12 columns">
                      <p>{lang.noticeOfCollection}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="large-2 columns">&nbsp;</div>
                    {/*validateMessage && <div className="large-8 columns text-right invalid" >{validateMessage}</div>*/}
                    <div className="large-2 columns">
                      {processing ?
                        <button className="button" style={{ display: "inline-block" }}><i className='fa fa-spinner fa-spin'></i>{lang.processing}</button>
                        :
                        <button className="button" style={{ display: "inline-block" }} onClick={e => {
                          if (Object.keys(vErrors).length) {
                            setShowValidator(true);
                            //setValidateMessage(lang.eInvalidGenaral);
                            setErrorSectionErrors({...vErrors});
                            return;
                          } else {
                            //setValidateMessage(null);
                            setErrorSectionErrors(null);
                            savedAttendee ? setShowAgreement(true) : preparePay({ event, company, attendees, payParams, query, lang });
                          }
                        }}>{event.onestepreg === "N" ? lang.expressInterest : Number(event.eventfee) ? lang.registerAndPay : lang.register}</button>
                      }
                    </div>
                  </div>
                </FieldsBox>
                </>
              }
  </>
}