import React, { useContext } from 'react';
import { FieldsBox } from "./FieldsBox";
import { I18NContext } from "./Contexts";
import { Validator } from "./Validator";

const vPayAmount = amount => {
  return amount <= 1 ? "eInvalidPay" : "";
}

export const Checkout = ({ payParams, setPayParams, flexible, event }) => {
  const lang = useContext(I18NContext);
  return <FieldsBox>
    <div className="row">
      <div className="large-7 columns">
        {
          Number(event.eventfee)
            ? <div>
              <legend tabIndex="0" style={{ display: "inline-block", float: "left" }}>
                {lang.yourTotalFee}:
              </legend>
              <label tabIndex="0" style={{ display: "inline-block", float: "left", margin: "0.35rem" }}>
                {flexible && <Validator id="totalFee" value={payParams.payamount} vFuncs={[vPayAmount]} />}
              </label>
            </div>
            : ""
        }
      </div>
      <div className="large-12 columns">
        {
          Number(event.eventfee)
            ? flexible
              ? <input
                type="number"
                value={payParams.payamount}
                onChange={e => {
                  setPayParams({ ...payParams, payamount: e.target.value });
                }} />
              : <p tabIndex="0">${payParams.payamount}</p>
            : <legend tabIndex="0">{lang.noCharge}</legend>
        }
      </div>
    </div>
  </FieldsBox >
}