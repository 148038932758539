import React, { useContext, useRef, useEffect } from 'react';
import { FieldsBox } from "./FieldsBox";
import { I18NContext } from "./Contexts";

//{"mess":"Registration Successfully","mess_fr":null,"status":"OK","regRequest":{"ccpayrequest":{"command":"INIT_PAYMENT","payamount":"0","urlOnCancel":"http://localhost:3002","urlOnSuccess":"http://localhost:3002","lang":"en","proddes":"webinar test 2 -do not change","proddesfr":"webinar test 2","promotioncode":null,"pdasigned":""},"attendees":[{"wid":"","title":"Mr.","firstname":"a","lastname":"b","phone":"1222222222","email":"1@ji.com"},{"wid":"","title":"Dr.","firstname":"2","lastname":"2","phone":"1111111111","email":"2@i.c"}],"eventid":"7f0b98bb-ad68-4f00-8c6b-5fe6cc777b59","accountname":"tangsabrina5","companyaddress1":"xxx","companyaddress2":"","companycity":"toronto","companyprov":null,"companyzip":null,"companycountry":null,"contactfirstname":null,"contactlastname":null,"contactemail":null,"participanttype":"interest","accountbn":"123456789","accountorgtype":null},"ccpayResp":null}
//http://localhost:3002/?tid=Q1w4DMK7pn&ccpayct=86
//{"aEvent":{"eventid":"5d7089fc-be28-450c-863e-ff4bc10d763b","eventname":"conference event do not use","eventstart":"1/13/2021 3:35:14 PM","eventend":"1/21/2021 5:35:14 PM","eventdesp":"description here. here- last time","eventhelp":"1234567890","eventfee":"90.00","eventcap":"1000","registeredcount":"21","eventseatsleft":979,"eventloc":"220 yonge st,toronto,M5B 2H1","eventwebsite":"https://uat-ecrm-app.azurewebsites.net/?eventid=5D7089FC-BE28-450C-863E-FF4BC10D763B","eventdespfr":"La vie est belle","eventnamefr":"conference event fr","eventwebsitefr":null,"eventtype":"Incoming Buyers","eventformat":"On site","eventstatus":null,"regenddate":null,"rest":"Microsoft.Xrm.Sdk.EntityReference","countdowndays":null,"setregenddate":"False","promotioncode":null,"flexbilefee":"True","onestepreg":"Y","orgemail":"Farnaz.Goortani@ontario.ca","orgbranch":"MGCS","orgmailaddress":"222 Jarvis st. Toronto ON M2M3V4","bnrequired":"Y","eventdate":"01/13/2021-01/21/2021"},"aCompany":{"CompanyName":"tangsabrina5","CompanyGId":"ed9e2021-9a02-eb11-a813-000d3af3e51b","CompanyAddress":"xxx","CompanyPhone":null,"CompanyPostalCode":null,"CompanyProv":null,"Companycity":"toronto","companyType":null,"BusinessNumber":"123456789","pcontact":{"wid":"","title":null,"firstname":null,"lastname":null,"phone":null,"email":null}},"attendee":[{"wid":"","title":"Dr.","firstname":"fn","lastname":"ln","phone":"1111111111","email":"a@b.co"},{"wid":"","title":"Dr.","firstname":"fn","lastname":"ln1","phone":"1222222222","email":"a@b.c"}],"regstatus":"Paid"}
/*
              <p/>
              <dt>Company contact information:</dt>
              <dd>{company.pcontact.firstname}</dd>
              <dd>{company.pcontact.phone}</dd>
              <dd>{company.pcontact.email}</dd>
              */

export const Summary = ({ event, company, attendees, extraInfo }) => {
  const lang = useContext(I18NContext);

  const focusRef = useRef();
  useEffect(() => {
    console.log(focusRef.current);
    if (focusRef.current) {
      focusRef.current.focus();
      focusRef.current = null;
    }
  });

  return <div style={{ maxWidth: "100%", backgroundColor: "#ffffff", margin: "0", padding: "10px 0" }}>
    <div className="row">
      <div className="large-12 columns">
        <div className="reg-summary" tabIndex="0" ref={focusRef}>
          <div className="callout alert-callout success">
            {lang[extraInfo.formtype + "SummarySuccess"](lang.lang === "fr" ? event.eventnamefr : event.eventname)}
          </div>
        </div>
        <FieldsBox className="hidden">
          <div className="row">
            <div className="large-12 columns" tabIndex="0">
              <legend>{lang.companyInformation}</legend>
              <dl>
                <dd>{lang.companyLegalName}:{company.companyname || company.CompanyName}</dd>
                <dd>{lang.addressLine1}:{company.companyaddress || company.CompanyAddress}</dd>
                <dd>{lang.addressLine2}:</dd>
                <dd>{lang.city}:{company.companycity || company.Companycity} {lang.province}:{company.companyprov || company.CompanyProv} {lang.postCode}:{company.companypostalcode || company.CompanyPostalCode} </dd>
                <dd>{lang.country}: {company.companycountry || company.CompanyCountry}</dd>
              </dl>
            </div>
          </div>
        </FieldsBox>
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns" tabIndex="0">
              <legend>{lang.attendeeInformation}:</legend>
              <dl>
                {attendees.map(a => <>
                  <dd>{a.firstname} {a.lastname}</dd>
                  {a.phone && <dd>{a.phone}</dd>}
                  <dd>{a.email}</dd>
                  <br />
                </>)}
              </dl>
            </div>
          </div>
        </FieldsBox>
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns" tabIndex="0">
              <legend>{extraInfo.formtype === "form3" ? lang.summaryRegistrationDetail : lang.summaryApplicationDetail}</legend>
              <dl>
                <dd>{lang.dateTime}: {extraInfo.datetimeinfo}</dd>
                <dd>{extraInfo.tid && (lang.subCN + ": " + extraInfo.tid)}</dd>
                {extraInfo.payamount && <dd>{Number(extraInfo.payamount) ? (lang.totalFee + ": $" + extraInfo.payamount) : lang.feeWaived}</dd>}
                {extraInfo.ccpayid && <dd>{lang.payCN}: {extraInfo.ccpayid}</dd>}
                {extraInfo.cardtype && <dd>{lang.cardType}: {extraInfo.cardtype}</dd>}
                <dd>
                  <span style={{ display: "inline-block", float: "left" }}>{lang.submitTo}:</span>
                  <span style={{ display: "inline-block", float: "left", marginLeft: "0.25rem" }}>
                    {lang.lang === "fr" ? event.orgbranch_fr : event.orgbranch}
                    <br />
                    {lang.lang === "fr" ? event.orgmailaddress_fr : event.orgmailaddress}
                  </span>
                </dd>
              </dl>
            </div>
          </div>
        </FieldsBox>
        <FieldsBox>
          <div className="row">
            <div className="large-12 columns">
              <legend>{lang.viewCalendar}</legend>
            </div>
          </div>
        </FieldsBox>
        <div className="reg-summary" tabIndex="0">
          <p>{lang.noticeOfCollection}</p>
        </div>
      </div>
    </div>
  </div >;
}