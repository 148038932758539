import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import * as R from "ramda";
import { I18NContext } from "./Contexts";
import { Validator, vEmail } from "./Validator";
import { FieldsBox } from "./FieldsBox";

const vPhone = phone => {
  if (!(/^\d{10}/.test(phone))) {
    return "eInvalidPhone";
  }
}

export const Attendee = ({ attendee, onChange, idx }) => {
  const lang = useContext(I18NContext);
  const handleChange = useCallback(e => {
    onChange({ ...attendee, [e.target.name]: e.target.value });
  }, [attendee, onChange]);
  return <div id={"participant_registration_fields" + idx}>
    <div className="row">
      <div className="large-4 columns">
        <label htmlFor={"participant_first_name" + idx}>{lang.firstName}: <Validator id={idx==="primary"?"eventContact,:,firstName":`attendee, ${idx}:,firstName`} value={attendee.firstname} /></label>
        <input type="text" name="firstname" id={"participant_first_name" + idx} value={attendee.firstname??""} onChange={handleChange} size="20" />
      </div>
      <div className="large-4 columns">
        <label htmlFor={"participant_last_name" + idx}>{lang.lastName}: <Validator id={idx==="primary"?"eventContact,:,lastName":`attendee, ${idx}:,lastName`} value={attendee.lastname} /></label>
        <input type="text" name="lastname" id={"participant_last_name" + idx} value={attendee.lastname??""} onChange={handleChange} size="20" />
      </div>
      <div className="large-4 columns">
        <label htmlFor={"participant_title_prefix" + idx}>{lang.titlePrefix}: {lang.optional}</label>
        <input type="text" name="title" id={"participant_title_prefix" + idx} value={attendee.title??""} onChange={handleChange} size="20" />
        <div className="error hide">Select a prefix</div>
      </div>
    </div>
    <div className="row">
      <div className="large-7 columns">
        <label htmlFor={"participant_business_email" + idx}>{lang.email}: <Validator id={idx==="primary"?"eventContact,:,email":`attendee, ${idx}:,email`} value={attendee.email} vFuncs={[vEmail]} /></label>
        <input type="text" name="email" id={"participant_business_email" + idx} value={attendee.email??""} onChange={handleChange} size="20" />
      </div>
      <div className="large-5 columns">
        <label htmlFor={"participant_business_telephone" + idx}>{lang.phone}:  <Validator id={idx==="primary"?"eventContact,:,phone":`attendee, ${idx}:,phone`} value={attendee.phone} /></label>
        <input className="phone" type="number" name="phone" id={"participant_business_telephone" + idx} value={attendee.phone??""} onChange={handleChange} maxLength="10" size="20" />
      </div>
    </div>
  </div>
}

export const Attendees = props => {
  const lang = useContext(I18NContext);
  const { attendees, setAttendees, seatsLeft, pcontact, setpcontact, eventmaxregpercompany } = props;
  const [errMsg, setErrMsg] = useState(null);
  const focusRef = useRef();
  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);

  return <FieldsBox>
    <div className="row">
      <div className="large-12 columns" >
        <legend tabIndex="0" ref={focusRef}>{lang.addAttendee}</legend>
        <p tabIndex="0">{lang.minAttendee}</p>
        <p tabIndex="0">{lang.maxAttendee(eventmaxregpercompany??3)}</p>
      </div>
    </div>
    {attendees.map((a, i, as) => <div key={i} style={{ border: "1px solid #cacaca", padding: "1rem" }}>
      <div className="row">
        <div className="large-3 columns" tabIndex="0" aria-label={"attendee number " + (i + 1)} ref={i > 0 ? focusRef : null}><span className="label">{i + 1}</span></div>
        <div className="large-3 columns text-right">
          <button className="button"
            onClick={e => {
              if (as.length === 1) return;
              setErrMsg(null);
              setAttendees(R.remove(i, 1, as))
            }}>{lang.deleteattendee}</button>
        </div>
      </div>
      <Attendee key={a.name} idx={i+1} attendee={a} onChange={a => setAttendees(R.set(R.lensIndex(i), a, as))} />
      {pcontact && <label> <input type="checkbox" checked={a===pcontact} onChange={e=>setpcontact(e.target.checked?a:{})}/> {lang.setAsPContact}</label>}
    </div>)}
    <div className="row add_participant_wrapper_initial">
      <div className="large-3 columns text-right">&nbsp;</div>
      {errMsg && <div className="large-6 columns text-right" style={{ color: "red" }}>{errMsg}</div>}
      <div className="large-3 columns text-right">
        <button tabIndex="0" className="button large-12 medium-4 small-12" style={{ display: (attendees.length < (eventmaxregpercompany??3)) ? "" : "none" }} onClick={e => {
          if (attendees.length === seatsLeft) {
            setErrMsg(lang.seatsLeft(seatsLeft));
            return;
          }
          setAttendees([...attendees, {}])
        }}>{lang.addAnother}</button>
      </div>
    </div>
  </FieldsBox>;
}