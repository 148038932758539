import React, {useState, useContext, useRef, useEffect} from 'react';
import { Attendees, Attendee } from "./Attendees";
import { FieldsBox } from "./FieldsBox";
import { SavedCompany, SearchCompany, CompanyInfo } from "./CompanyInfo";
import { ErrorSection} from "./ErrorSection";
import { I18NContext } from "./Contexts";
import { AdditionalCompanyInfo } from './AdditionalCompanyInfo';
import { Checkout } from "./Checkout";

const RadioGroup = ({name, options, value, onChange})=>{
  return <div onChange={e=>{console.log("radio changed", [e.target.value]);onChange(e.target.value)}}>
    {options.map(([option,v])=> <React.Fragment key={option} ><input tabIndex="0" id={option} type="radio" value={v} name={name} /> <label tabIndex="0" htmlFor={option}>{option}</label></React.Fragment>)}
  </div>;
}

const PreQualifyQuestions = ({value, onChange, event})=>{
  const lang = useContext(I18NContext);
  const focusRef = useRef();
  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);
  return <FieldsBox>
    <div className="row">
      <div className="large-12 columns" tabIndex="0">
        <legend tabIndex="0" ref={focusRef}>{lang.tsPrequalifyQuestions}</legend>
      </div>
    </div>
    <div className="row">
      <div className="large-9 columns">
        <label tabIndex="0">{lang.rOntario}</label>
        <div onChange={e=>onChange({...value, rOntario:e.target.value==="yes"})}>
          <input tabIndex="0" id="rOntario-yes" type="radio" value="yes" checked={value.rOntario===true} name="rOntario" /> <label tabIndex="0" htmlFor="rOntario-yes">{lang.yes}</label>
          <input tabIndex="0" id="rOntario-no" type="radio" value="no" checked={value.rOntario===false} name="rOntario" /> <label tabIndex="0" htmlFor="rOntario-no">{lang.no}</label>
        </div>
      </div>
    </div>
    {value.rOntario!==undefined && <div className="row">
      <div className="large-9 columns">
        <label tabIndex="0">{lang.mpsOntario}</label>
        <div onChange={e=>onChange({...value, mpsOntario:e.target.value==="yes"})}>
          <input tabIndex="0" id="mpsOntario-yes" type="radio" value="yes" checked={value.mpsOntario===true} name="mpsOntario" /> <label tabIndex="0" htmlFor="mpsOntario-yes">{lang.yes}</label>
          <input tabIndex="0" id="mpsOntario-no" type="radio" value="no" checked={value.mpsOntario===false} name="mpsOntario" /> <label tabIndex="0" htmlFor="mpsOntario-no">{lang.no}</label>
        </div>
      </div>
    </div>}
  </FieldsBox>
}

export const TwoStepForm = props=>{
  const lang = useContext(I18NContext);
  const {
    savedCompany, useSavedCompany, confirmCompany, selectedCompany, onSelectCompany, searchResult, setSearchResult, searchText, setSearchText,
    company,
    errorSectionErrors,
    addCompany,
    event,
    onAddCompanyChange,
    cp,
    setAttendees, attendees,
    setPayParams, payParams,
    companymoreinfo, setCompanyMoreInfo,
    processing, vErrors, setShowValidator, setErrorSectionErrors, preparePay, query
  } = props;
  const [pcontact, setpcontact] = useState({});
  const [prequalify, setPrequalify] = useState({
    rOntario:undefined,
    mpsOntario:undefined,
  });

  const [moreInfoConfirmed, setMoreInfoComfirmed] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  return <>
    <PreQualifyQuestions value={prequalify} onChange={setPrequalify} event={event}/>
    {prequalify.mpsOntario===false && <FieldsBox> {lang.tsThanks(event)} </FieldsBox>} 
    { prequalify.mpsOntario && <>
      {savedCompany && <SavedCompany company={savedCompany} useSaved={useSavedCompany} confirm={confirmCompany} />}
      {(!savedCompany || useSavedCompany === false)
        && <SearchCompany selectedCompany={selectedCompany} onSelectedCompany={onSelectCompany} {...{ searchResult, setSearchResult, searchText, setSearchText }} />}
      {company && errorSectionErrors && <ErrorSection errors={errorSectionErrors}/>}
      {addCompany && <CompanyInfo company={addCompany} bnrequired={event.bnrequired} onChange={onAddCompanyChange} cp={cp} />}
      {company && <AdditionalCompanyInfo value={companymoreinfo} onChange={setCompanyMoreInfo} onContinue={moreInfoConfirmed ? null:()=>setMoreInfoComfirmed(true)}/>}
      {company && moreInfoConfirmed && <>
        <Attendees attendees={attendees} seatsLeft={event.eventseatsleft} setAttendees={as => {
            setAttendees(as);
            if(event.flexbilefee!=="True")
              setPayParams({ ...payParams, payamount: as.length * +event.eventfee }); 
          }}
          pcontact={pcontact}
          setpcontact={setpcontact}
          eventmaxregpercompany={event.eventmaxregpercompany}
        />
        <FieldsBox>
            <div className="row">
              <div className="large-12 columns" tabIndex="0">
                <legend>{lang.eventContact}</legend>
              </div>
            </div>

            <Attendee attendee={pcontact} onChange={a => setpcontact(a)} idx="primary"/>
        </FieldsBox>
        {/*<Checkout payParams={payParams} setPayParams={setPayParams} flexible={event.flexbilefee === "True"} event={event} />*/}
        <FieldsBox>
          <div className="row" tabIndex="0">
            <div className="large-12 columns">
              <p>{lang.noticeOfCollection2Step}</p>
            </div>
          </div>
          <div className="row" style={{display:"flex"}}>
            <div style={{marginLeft:"1rem"}} > <input type="checkbox" name="receiveInfo" checked={receiveEmail} id="receiveInfo" onChange={e=>setReceiveEmail(e.target.checked)}/></div>
            <div style={{marginLeft:"1rem"}} ><label htmlFor="receiveInfo"><strong>{lang.receiveInfo}</strong></label></div>
          </div>
          <div className="row">
            <div className="large-2 columns">&nbsp;</div>
            {/*validateMessage && <div className="large-8 columns text-right invalid" >{validateMessage}</div>*/}
            <div className="large-2 columns">
              {processing ?
                <button className="button" style={{ display: "inline-block" }}><i className='fa fa-spinner fa-spin'></i>{lang.processing}</button>
                :
                <button className="button" style={{ display: "inline-block" }} onClick={e => {
                  if (Object.keys(vErrors).length) {
                    setShowValidator(true);
                    setErrorSectionErrors({...vErrors});
                    return;
                  } else {
                    setErrorSectionErrors(null);
                    preparePay({ event, company, attendees, payParams, query, lang, pcontact, emailallow:receiveEmail?"Yes":"No",
                      companymoreinfo:{
                        ...companymoreinfo,
                        businessIndustry:companymoreinfo.businessIndustry.join(),
                        sellcountry:companymoreinfo.sellcountry.join(),
                        regObjective:companymoreinfo.regObjective.join()
                      },
                      cost:payParams.payamount
                    });
                  }
                }}>{lang.expressInterest}</button>
              }
            </div>
          </div>
        </FieldsBox>
      </> }
      </>
    }
  </>;
};