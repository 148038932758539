export const html=`<p>
<strong></strong>
</p>
<p align="center">
<strong>Ministry of Economic Development, Job Creation and Trade</strong>
</p>
<p align="center">
<strong>International Business Growth Branch Staff-led Mission to </strong>
<strong>[state location, and start date to end date]</strong>
</p>
<p align="center">
<strong>MISSION PARTICIPATION AGREEMENT</strong>
</p>
<p align="center">
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<div>
<div style="width: 80em; float: left;">
<strong>THE AGREEMENT </strong>
is effective as of the <strong>[insert date]</strong>
</div>
<div>
<button class="button large-2 medium-2 small-12" onclick="window.print();">
    Print Agreement
    </button>
</div>
<div>
<p>
<strong></strong>
</p>
<p>
<strong>TO: &ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;</strong>
<strong>
    His Majesty the King in right of Ontario as represented by the
    Minister of Economic Development, Job Creation and Trade
</strong>
<strong>(the “Ministry”)</strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>FROM: &ensp;&ensp;&ensp;[Insert full legal name of the Company] </strong>
<strong>(the “Delegate”)</strong>
</p>
<p>
<strong>WHEREAS</strong>
the Ministry is leading the Mission to <strong>[state location, and start date to end date]</strong> (“    <strong>Mission</strong>”) and the Delegate has been conditionally approved
to participate in such Mission;<strong></strong>
</p>
<p>
<strong>AND WHEREAS</strong>
, as a condition of its participation in the Mission and prior to the
completion of its registration, the Delegate must read, agree to, and sign
this Agreement by electronic signature, including Schedule A (Waiver,
Release and Indemnity Form) (“    <strong>Waiver, Release and Indemnity Form</strong>”), evidencing its
acceptance of the terms and conditions of such participation;
</p>
<p>
<strong></strong>
</p>
<p>
<strong>NOW THEREFORE</strong>
in consideration of the mutual covenants and agreements contained herein,
and for other good and valuable consideration, the receipt and sufficiency
of which are expressly acknowledged, His Majesty the King in right of
Ontario as represented by the Ministry and the Delegate (together with the
Ministry, the “<strong>Parties</strong>”) agree as follows:
</p>
<p>
<strong>1. Agreement - General. </strong>
</p>
<p>
(a) The Agreement and Waiver, Release and Indemnity Form constitute the
entire Agreement between the Parties with respect to the subject matter
contained in the Agreement and supersedes all prior oral or written
representations and agreements;
</p>
<p>
(b) The Ministry may amend the Agreement at any time by giving notice to
the Delegate. Notice of any such amendment will be communicated to the
Delegate by e-mail as soon as reasonably possible. Once notice is posted on
such webpage, it will be deemed to have been communicated to the Delegate.
Following any such notice, the Delegate must provide written confirmation
to the Ministry within 5 business days that it agrees or does not agree to
the amendments. If the Delegate does not agree to the amendments or
otherwise does not provide a written response to the Ministry within 5
business days from such notice, it must immediately withdraw from the
Mission; and
</p>
<p>
(c) The invalidity or unenforceability of any provision of the Agreement
will not affect the validity or enforceability of any other provision of
the Agreement.
</p>
<strong>2. Effective Date.</strong>
<p>
The Agreement is effective on the date set out at the top of the Agreement
and subject to Article 10 of the Agreement, will expire on <strong>[insert eventend]</strong>
 unless terminated earlier pursuant to Article 9 of the Agreement.
</p>
<p>
<strong>3. FIPPA and Third Party Information.</strong>
The Government of Ontario (“<strong>Ontario</strong>”) is bound by the    <em>Freedom of Information and Protection of Privacy Act</em>, R.S.O. 1990,
c. F.31 (“<strong>FIPPA</strong>”), as amended from time to time, and any
such information provided to Ontario, including to the Ministry, may be
subject to disclosure under the FIPPA. Section 17 of the FIPPA provides an
exemption from the disclosure of certain third party information that
reveals a trade secret or scientific, technical, commercial, financial or
labour relations information supplied in confidence where disclosure of the
information could reasonably be expected to result in certain harms. The
Delegate agrees that any trade secret or scientific, technical, commercial,
financial or labour relations information submitted by a Delegate in
confidence to the Ministry will be clearly marked by the Delegate as such.
The Delegate acknowledges that the Ministry will provide notice to the
affected Delegate before granting access to a record that might contain
information referred to in Section 17 of the FIPPA so that the affected
Delegate may make representations to the Ministry concerning disclosure.
</p>
<p>
<strong>4. Delegate Representations, Warranties, and Covenants.</strong>
The Delegate represents, warrants, and covenants to the Ministry that:
</p>
<p>
(a) It is, and will continue to be, a validly existing legal entity with
full power and authority to fulfill its obligations under the Agreement;
</p>
<p>
(b) It has (i) the full power and capacity to enter into the Agreement; and
(ii) taken all the necessary actions to authorize the execution of the
Agreement;
</p>
<p>
(c) It is in compliance with, and will continue to comply with, all
Canadian federal and provincial laws and regulations, all municipal
by-laws, and any other orders, rules, and by-laws related to any aspect of
the Agreement, the Mission, or both;
</p>
<p>
(d) Its Materials, as defined hereinunder, and any other information the
Delegate has provided to the Ministry related to any aspect of the
Agreement, the Mission, or both, was true and complete at the time the
Delegate provided it and will continue to be true and complete;
</p>
<p>
(e) It will not assign any of its rights or obligations under the
Agreement. In the event the Delegate is unable to attend the Mission, it
will provide written notice of its cancellation to the Ministry as soon as
reasonably possible and without any undue delay in advance of the start
date of the Mission;<strong><em></em></strong>
</p>
<p>
<strong><em></em></strong>
</p>
<p>
(f) The Delegate’s employees, servants, or agents, or any guests who may be
accompanying it (collectively, the “<strong>Delegate Personnel</strong>”), will each possess a valid passport
for the duration of the Mission;
</p>
<p>
(g) It will (i) attend all required official Mission programming; (ii)
complete a mission evaluation form satisfaction survey at the completion of
the Mission; and (iii) share a summary of the Delegate’s results from the
Mission (for example, any sales, contracts, memoranda of understanding or
joint ventures that it has entered into) with the Ministry at the completion of the Mission
, and then at 6-month, 12-month and 18-month intervals thereafter
(collectively, the “<strong>Delegate’s Mission Results</strong>
”). The Delegate acknowledges that the information collected by the
Ministry under this Section 4(f) will be treated as confidential subject to
the Government of  Ontario’s FIPPA obligations and will only be used to report back to the
Ministry the aggregated results from all Mission participants;
</p>
<p>
(h) It will be responsible for any costs it or its Delegate Personnel may
incur including costs related to airfare, transfers to and from airports at
the start and at the end of the Mission, accommodation, meals, incidentals,
Delegate staffing or representative needs, and public engagement or
promotional efforts specific to that Delegate;
</p>
<p>
(i) Upon reasonable request of the Ministry, it will provide to the
Ministry certain materials to assist with the administration, communication
and promotion of the Mission (the “<strong>Materials</strong>”);
</p>
<p>
(j) To the extent that the Materials contain, in whole or in part,
intellectual property of the Delegate or of any third party, it grants to
the Ministry a perpetual, world-wide, non-exclusive, irrevocable,
transferable, royalty-free, fully paid up right and license to (i) use,
modify, reproduce, publish and distribute, in any form, the Materials; and
(ii) authorize other persons to use, modify, reproduce, publish and
distribute, in any form, the Materials on behalf of the Ministry in order
to communicate or promote the Mission. The Delegate irrevocably waives in
favour of the Ministry all rights of integrity and other moral rights in
the Materials, for all time;
</p>
<p>
(k) Notwithstanding Section 4(j), the Materials will not infringe or induce
the infringement of any third party intellectual property rights;
</p>
<p>
(l) It will waive any rights to inspect or approve the finished media
communications and releases and will hold harmless His Majesty the King in
right of Ontario, including Her ministers, agents, appointees, and
employees (the “<strong>Indemnified Parties</strong>”), from all actions,
claims and demands arising from the Ministry’s use, modification,
reproduction, publication, and distribution of any Materials and any use or
misuse, including but not limited to, any alteration, modification, reuse
or distortion of the Materials that may occur or be caused by third
parties, including the media; and
</p>
<p>
(m) It will not use any insignia or logo of His Majesty the King in right
of Ontario unless it has received the prior written consent of the Ministry
to do so.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>5. Ministry Representations, Warranties, and Covenants.</strong>
The Delegate acknowledges that:
</p>
<p>
(a)
    For the purposes of the Mission, the Ministry will charge registration
fees to the Delegate in the amount of CAD <strong>[insert fee amount]</strong> (“<strong>Registration Fees</strong>”). The Delegate will pay the full
    amount of the Registration Fees 1 business day after the Delegate
    confirms to the Ministry receipt of the credit card payment link (also
    known as “CCPay”) sent by the Ministry. The Delegate will have 5
    business days from the date of full payment of the Registration Fees to
    a full reimbursement of such fees. Outside of this stated time period,
    and at the sole discretion of the Ministry, the Ministry may provide
    the Delegate with a full reimbursement of its Registration Fees;
</p>
<p>
(b) As part of the Registration Fees to be charged to the Delegate in
Section 5(a), the Ministry will provide the Delegate with the activities in
the official Mission program, including pre-Mission activities and
activities for the duration of the Mission; and
<s>
</s>
</p>
<p>
(c) If requested by the Delegate, the Ministry will provide a Confirmation
Letter of Mission Participation to the Delegate and Delegate Personnel, as
the case may be, in respect of the
</p>
<p>
Mission for the purpose of obtaining a visa(s) for all Mission country/ies.
It is the responsibility of the Delegate to obtain and pay for the cost of
any and all required visa(s), including those for Delegate Personnel. Upon
reasonable request of the Delegate, the Ministry will provide any
additional information that may be required.
</p>
<p>
<strong><em></em></strong>
</p>
<p>
<strong>6. Compliance with Foreign Laws.</strong>
The Delegate acknowledges that for the duration of its participation in the
Mission, the Delegate and Delegate Personnel are expected to comply with
all national, subnational, and local laws and regulations, and any other
orders, rules, and by-laws in the Mission host country/ies.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>7.</strong>
<strong>Insurance and Indemnity. </strong>
The Delegate represents, warrants, and covenants that:
</p>
<p>
(a) It has, and will maintain, at its own cost and expense, with insurers
having a secure A.M. Best rating of B+ or greater, or the equivalent, all
the necessary and appropriate insurance that a prudent person carrying out
the business of the Delegate would maintain, including commercial general
liability insurance on an occurrence basis for third party bodily injury,
personal injury, and property damage, to an inclusive limit of not less
than $2,000,000 per occurrence, which commercial general liability
insurance policy will include the following (i) the Indemnified Parties as
additional insureds with respect to liability arising in the course of
performance of the Delegate’s obligations under, or otherwise in connection
with, the Agreement or participation in the Mission; (ii) a cross-liability
clause; (iii) contractual liability coverage; and (iv) a thirty (30) day
written notice of cancellation;
</p>
<p>
(b) The Delegate will indemnify and hold harmless the Indemnified Parties
from and against any (i) cause of action, liability, loss, cost, damage, or
expense (including legal, expert and consultant fees) that anyone incurs or
sustains as a result of or in connection with the Delegate or Delegate
Personnel’s participation in the Mission or any part of the Agreement; and
(ii) action, claim, demand, lawsuit, or other proceeding that anyone makes,
brings or prosecutes as a result of or in connection with the Delegate or
Delegate Personnel’s participation in the Mission or any part of the
Agreement, unless solely caused by the negligence or wilful misconduct of
the Indemnified Parties; and
</p>
<p>
(c) In addition to the foregoing, the Delegate will submit to the Ministry
a duly executed Waiver, Release and Indemnity Form found here    <strong>See Schedule A (Waiver, Release and Indemnity Form)</strong> prior to the
completion of its registration. The duly executed Waiver, Release and
Indemnity Form will be submitted to the Ministry at the same time as the
duly executed Agreement.
</p>
<p>
<strong>8. Parties Independent.</strong>
The Delegate or any Delegate Personnel are not an agent, joint venturer,
partner, or employee of the Ministry, and the Delegate or any Delegate
Personnel will not represent itself in any way that might be taken by a
reasonable person to suggest that it is or take any actions that could
establish or imply such a relationship.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>9. Termination on Notice and Cancellation of Mission.</strong>
The Delegate acknowledges that:
</p>
<p>
(a) The Ministry may terminate the Agreement at any time without liability,
penalty or costs upon giving notice to the Delegate;
</p>
<p>
(b) If the Ministry cancels the Mission for reasons within its control, it
will reimburse reasonable, Mission-related costs (without interest)
incurred and paid for by the Delegate provided that the Delegate (i)
produces true and complete copies of invoices and/or receipts in support of
such costs; and (ii) retains original invoices and/or receipts to support such
costs, which may be subject to review by the Ministry;
</p>
<p>
(c) In the event the Ministry cancels the Mission pursuant to Section 9(b),
the Delegate will make best efforts to minimize or mitigate any costs for
the Mission already incurred, that are incurring, and which may incur;
</p>
<p>
(d) The Ministry will rely on Ontario’s    <em>Travel, Meal and Hospitality Expenses Directive</em>, as it may be
amended from time to time <a href="https://www.ontario.ca/page/travel-meal-and-hospitality-expenses-directive-2020">
 https://www.ontario.ca/page/travel-meal-and-hospitality-expenses-directive-2020 </a>, for the
purposes of determining what are reasonable costs that the Ministry will
reimburse under Section 9(b);
</p>
<p>
(e) Notwithstanding Section 9(b), if unforeseen events outside the
Ministry’s control lead to the postponement or cancellation of the Mission,
including those in relation to third party actions, the Ministry will only
reimburse (without interest) the Delegate’s paid Registration Fees, as set
out in Section 5(a) of the Agreement; and
</p>
<p>
(f) Notwithstanding Sections 9(b) and (e), the Ministry will assume no
other liability, penalty or costs.
</p>
<p>
<strong>10.</strong>
<strong>Survival.</strong>
</p>
<p>
(a) Sections 4(g), (j), (k), (l), (m) and (n); Article 7; and Section 10(a)
of the Agreement, and the Waiver, Release and Indemnity Form, will survive
any termination, cancellation or expiry of the Agreement; and
</p>
<p>
(b) Sections 1(a) and (c); Section 4(a); Article 8; Article 9; Section
10(b), and Article 11 of the Agreement, and any applicable definitions of
the Agreement, will continue in full force and effect for a period of 7
years from the date of expiry or termination of the Agreement.
</p>
<p>
<strong>11. Governing Law.</strong>
The Agreement and the rights, obligations and relations of the Parties will
be governed by and construed in accordance with the laws of the Province of
Ontario and the applicable federal laws of Canada. Any actions or
proceedings arising in connection with the Agreement or Mission will be
conducted in the courts of Ontario, which will have exclusive jurisdiction
over such proceedings.
</p>
<p>
<strong>12. Execution, Confirmation and Transmission. </strong>
The Delegate acknowledges and agrees that:<strong></strong>
</p>
<p>
(a) The Parties agree that the Agreement and Waiver, Release and Indemnity
Form may be validly executed electronically by the Delegate, and that the
Delegate’s respective electronic signature is the legal equivalent of a
manual signature. The electronic signature of the Delegate may be evidenced
as follows (i) a digital signature, including the name of the authorized
signing representative typed in the respective signature line of the
Agreement and Waiver, Release and Indemnity Form or any other means with
the Ministry’s prior written consent; and (ii) the Agreement and Waiver,
Release and Indemnity Form delivered by email by the Delegate to the
Ministry;
</p>
<p>
(b) Following electronic execution by the Delegate of the Agreement and
Waiver, Release and Indemnity Form, the undersigned Delegate will send a
confirmation email to the Ministry from a verifiable email account stating
that (i) it has duly executed the Agreement, including the Waiver, Release
and Indemnity Form attached as Schedule A to the Agreement; (ii) it is duly
authorized to bind the Delegate with its signature; and (iii) it will provide the date
and time of the Delegate’s signature; and
</p>
<p>
(c) The duly executed Agreement and Waiver, Release and Indemnity Form must
be returned by the Delegate to the Ministry at the same time and in the
same email.
</p>
<p>
<strong>
    The undersigned Delegate hereby acknowledges that it has read and
    understood the terms and conditions of the Agreement, including the
    Waiver, Release and Indemnity Form attached as Schedule A to the
    Agreement, and by providing its electronic signature to the Agreement,
    including on the Waiver, Release and Indemnity Form attached as
    Schedule A to this Agreement, the Delegate agrees to be bound by all
    the terms and conditions contained herein.
</strong>
</p>




<p align="center">
<strong>Ministry of Economic Development, Job Creation and Trade</strong>
</p>
<p align="center">
<strong>International Business Growth Branch Staff-led Mission to </strong>
<strong>[state location, and start date to end date]</strong>
</p>
<p align="center">
<strong>SCHEDULE A TO</strong>
</p>
<p align="center">
<strong>
    MISSION PARTICIPATION AGREEMENT effective as of [insert date] (the
    “Agreement”)
</strong>
</p>
<p align="center">
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>TO: &ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;</strong>
<strong>
    His Majesty the King in right of Ontario as represented by the
    Minister of Economic Development, Job Creation and Trade
</strong>
<strong>(the “Ministry”)</strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>FROM: &ensp;&ensp;&ensp;[Insert full legal name of the Company] </strong>
<strong>(the “Delegate”)</strong>
</p>
<p align="center">
<strong></strong>
</p>
<p align="center">
<strong></strong>
</p>
<p align="center">
<strong><u>WAIVER, RELEASE AND INDEMNITY FORM</u></strong>
</p>
<p align="center">
<strong></strong>
</p>
<p>
The Delegate is fully liable for any consequences of its participation in
the Mission to <strong>[state location, and start date to end date]</strong>
 (“<strong>Mission</strong>”). In consideration for being accepted to
participate in the Mission, the Delegate will indemnify and hold harmless
His Majesty the King in right of Ontario, including Her ministers, agents,
appointees, and employees (the “<strong>Indemnified Parties</strong>”),
from and against any (i) cause of action, liability, loss, cost, damage, or
expense (including legal, expert and consultant fees) that anyone incurs or
sustains as a result of or in connection with the Delegate or any of the
Delegate’s employees, servants or agents, or any guests who may be
accompanying it (collectively, the “<strong>Delegate Personnel</strong>”)
participation in the Mission or any part of the Agreement; and (ii) action,
claim, demand, lawsuit, or other proceeding that anyone makes, brings or
prosecutes as a result of or in connection with the Delegate or Delegate
Personnel’s participation in the Mission or any part of the Agreement,
unless solely caused by the negligence or wilful misconduct of the
Indemnified Parties.
<u>
    The Delegate acknowledges that it has advised Delegate Personnel in
    writing of the terms and conditions applicable to the Delegate
    Personnel contained herein.
</u>
</p>
<p>
The Delegate and Delegate Personnel and their heirs, executors and
administrators waive and release the Indemnified Parties from and against
all rights, claims, losses, etc. as identified above, whatsoever in
relation to the Mission or any part of the Agreement. The Delegate agrees
that its liability to indemnify or reimburse the Indemnified Parties under
this Agreement will not affect or prejudice the Indemnified Parties from
exercising any other rights under law.
<u>
    The Delegate acknowledges that it has advised Delegate Personnel in
    writing of the terms and conditions applicable to the Delegate
    Personnel contained herein.
</u>
</p>
<p>
The Delegate acknowledges that Delegate Personnel will be accompanying or participating in the Mission at their own risk and
that they are aware that they ought not to leave Canada without first
obtaining adequate insurance coverage for medical expenses, personal
accident, loss of baggage and curtailment or cancellation of trip and that
it is their sole responsibility to ensure that they obtain adequate
coverage and that even with insurance, individuals may be responsible to
finance on-the-spot medical treatment and other contingencies, and then to
reclaim this from insurance, if covered.
The Delegate and Delegate Personnel acknowledge the Indemnified Parties do
not have any responsibility to ensure that the Delegate or Delegate
Personnel have any insurance coverage or that any such coverage is adequate
and the Indemnified Parties shall in no way be liable for any expenses or
losses of the Delegate or
Delegate Personnel whatsoever including but in no way limited to any loss
that would be covered by out-of-country medical insurance, personal
accident, loss of baggage or curtailment or cancellation of trip insurance.
<u>
    The Delegate acknowledges that it has advised Delegate Personnel in
writing of the terms and conditions applicable to the Delegate
Personnel contained herein.
</u>

</p>
<p>
The Delegate and Delegate Personnel agree to release and hold harmless the Indemnified Parties from any and all
    liability whatsoever
    for any injuries, losses or damages of any kind arising from or in the
    course of (i) arranging travel or accommodation in relation to the
    Mission; or (ii) failure to make adequate travel or accommodation
    arrangements in relation to the Mission.
<u>
    The Delegate acknowledges that it has advised Delegate Personnel in
    writing of the terms and conditions applicable to the Delegate
    Personnel contained herein.
</u>
</p>
<p>
<u>Execution, Confirmation and Transmission</u>
<u></u>
</p>
<p>
The Parties agree that the Waiver, Release and Indemnity Form, attached as
Schedule A to the Agreement, may be validly executed electronically by the
Delegate, and that the Delegate’s respective electronic signature is the
legal equivalent of a manual signature. The electronic signature of the
Delegate may be evidenced as follows (i) a digital signature, including the
name of the authorized signing representative typed in the respective
signature line of the Waiver, Release and Indemnity Form or any other means
with the Ministry’s prior written consent; and (ii) the Agreement and
Waiver, Release and Indemnity Form delivered by email by the Delegate to
the Ministry.
</p>
<p>
Following electronic execution by the Delegate of the Agreement and Waiver,
Release and Indemnity Form, the undersigned Delegate will send a
confirmation email to the Ministry from a verifiable email account stating
that (i) it has duly executed the Agreement, including the Waiver, Release
and Indemnity Form attached as Schedule A to the Agreement; (ii) it is duly
authorized to bind the Delegate with its signature; and (iii) it will
provide the date and time of the Delegate’s signature.
</p>
<p>
The duly executed Agreement and Waiver, Release and Indemnity Form must be
returned by the Delegate to the Ministry at the same time and in the same
email.
</p>
<p>
<strong>
    The undersigned Delegate hereby acknowledges that it has read and
    understood the terms and conditions of the Agreement, including the
    Waiver, Release and Indemnity Form attached as Schedule A to the
    Agreement, and by providing its electronic signature to the Agreement,
    including on the Waiver, Release and Indemnity Form attached as
    Schedule A to this Agreement, the Delegate agrees to be bound by all
    the terms and conditions contained herein.
</strong>
</p>`