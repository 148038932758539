export const html=`<p>
<strong></strong>
</p>
<p align="center">
<strong>Ministry of Economic Development, Job Creation and Trade</strong>
</p>
<p align="center">
<strong>International Business Growth Branch Virtual Mission to </strong>
<strong>[state location, and start date to end date]</strong>
</p>
<p align="center">
<strong>VIRTUAL MISSION PARTICIPATION AGREEMENT</strong>
</p>
<p align="center">
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<div style="width: 80em; float: left;">
<strong>THE AGREEMENT </strong>
is effective as of the <strong>[insert date]</strong>
</div>
<div>
<button class="button large-2 medium-2 small-12" onclick="window.print();">
    Print Agreement
    </button>
</div>
<div>
<p>
<strong></strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>TO: &ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;</strong>
<strong>
    His Majesty the King in right of Ontario as represented by the
    Minister of Economic Development, Job Creation and Trade
</strong>
<strong>(the “Ministry”)</strong>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>FROM: &ensp;&ensp;&ensp;[Insert full legal name of the Company] </strong>
<strong>(the “Delegate”)</strong>
</p>
<p>
<strong>WHEREAS</strong>
the Ministry is organizing a virtual mission to <strong>[state location, and start date to end date]</strong> (“    <strong>Mission</strong>”) and the Delegate has been conditionally approved
to participate in such Mission;<strong></strong>
</p>
<p>
<strong>AND WHEREAS</strong>
, as a condition of its participation in the Mission and prior to the
completion of its registration, the Delegate must read, agree to, and sign
this Agreement by electronic signature evidencing its acceptance of the
terms and conditions of such participation;
</p>
<p>
<strong></strong>
</p>
<p>
<strong>NOW THEREFORE</strong>
in consideration of the mutual covenants and agreements contained herein,
and for other good and valuable consideration, the receipt and sufficiency
of which are expressly acknowledged, His Majesty the King in right of
Ontario as represented by the Ministry and the Delegate (together with the
Ministry, the “<strong>Parties</strong>”) agree as follows:
</p>
<p>
<strong>1. Agreement - General. </strong>
</p>
<p>
(a) The Agreement constitutes the entire Agreement between the Parties with
respect to the subject matter contained in the Agreement and supersedes all
prior oral or written representations and agreements;
</p>
<p>
(b) The Ministry may amend the Agreement at any time by giving notice to
the Delegate. Notice of any such amendment will be communicated to the
Delegate by e-mail as soon as reasonably possible. Once notice is posted by
e-mail, it will be deemed to have been communicated to the Delegate.
Following any such notice, the Delegate must provide written confirmation
to the Ministry within 5 business days that it agrees or does not agree to
the amendments. If the Delegate does not agree to the amendments or
otherwise does not provide a written response to the Ministry within 5
business days from such notice, it must immediately withdraw from the
Mission; and
</p>
<p>
(c) The invalidity or unenforceability of any provision of the Agreement
will not affect the validity or enforceability of any other provision of
the Agreement.
</p>
<p>
<strong>2. Effective Date.</strong>
The Agreement is effective on the date set out at the top of the Agreement
and subject to Article 9 of the Agreement, will expire on 
<strong>[set out number of days/months following the end of the Mission that you want Agreement to expire] </strong>
 unless terminated earlier pursuant to Article 8 of the Agreement.
</p>
<p>
<strong>3. FIPPA and Third Party Information.</strong>
The Government of Ontario is bound by the    <em>Freedom of Information and Protection of Privacy Act</em>, R.S.O. 1990,
c. F.31 (“<strong>FIPPA</strong>”), as amended from time to time, and any
such information provided to the Government of Ontario, including to the
Ministry, may be subject to disclosure under the FIPPA. Section 17 of the
FIPPA provides an exemption from the disclosure of certain third party
information that reveals a trade secret or scientific, technical,
commercial, financial or labour relations information supplied in
confidence where disclosure of the information could reasonably be expected
to result in certain harms. The Delegate agrees that any trade secret or
scientific, technical, commercial, financial or labour relations
information submitted by a Delegate in confidence to the Ministry will be
clearly marked by the Delegate as such. The Delegate acknowledges that the
Ministry will provide notice to the affected Delegate before granting
access to a record that might contain information referred to in Section 17
of the FIPPA so that the affected Delegate may make representations to the
Ministry concerning disclosure.
</p>
<p>
<strong>4. Delegate Representations, Warranties, and Covenants.</strong>
The Delegate represents, warrants, and covenants to the Ministry that:
</p>
<p>
(a) It is, and will continue to be, a validly existing legal entity with
full power and authority to fulfill its obligations under the Agreement;
</p>
<p>
(b) It has (i) the full power and capacity to enter into the Agreement; and
(ii) taken all the necessary actions to authorize the execution of the
Agreement;
</p>
<p>
(c) It is in compliance with, and will continue to comply with, all
Canadian federal and provincial laws and regulations, all municipal
by-laws, and any other orders, rules, and by-laws related to any aspect of
the Agreement, the Mission, or both;
</p>
<p>
(d) Its Materials, as defined hereinunder, and any other information the
Delegate has provided to the Ministry related to any aspect of the
Agreement, the Mission, or both, was true and complete at the time the
Delegate provided it and will continue to be true and complete;
</p>
<p>
(e) It will not assign any of its rights or obligations under the
Agreement. In the event the Delegate is unable to participate in the
Mission, it will provide written notice of its cancellation to the Ministry
as soon as reasonably possible and without any undue delay in advance of
the start date of the Mission;<strong><em></em></strong>
</p>
<p>
(f) It will (i) participate in all required official Mission programming;
(ii) complete a mission evaluation satisfaction survey at the completion of
the Mission; and (iii) share a summary of the Delegate’s results from the
Mission (for example, any sales, contracts, memoranda of understanding or
joint ventures that it has entered into) with the Ministry at the completion of the Mission, and then at
6-month, 12-month and 18-month intervals thereafter (collectively, the “<strong>Delegate’s</strong> <strong>Mission</strong>    <strong>Results</strong>”). The Delegate acknowledges that the information
collected by the Ministry under this Section 4(f) will be treated as
confidential subject to the Government of Ontario’s FIPPA obligations and
will only be used to report back to the Ministry the aggregated results
from all Mission participants;
</p>
<p>
(g) Upon reasonable request of the Ministry, it will provide to the
Ministry certain materials to assist with the administration, communication
and promotion of the Mission (the “<strong>Materials</strong>”);
</p>
<p>
(h) To the extent that the Materials contain, in whole or in part,
intellectual property of the Delegate or of any third party, it grants to
the Ministry a perpetual, world-wide, non-exclusive, irrevocable,
transferable, royalty-free, fully paid up right and license to (i) use,
modify, reproduce, publish and distribute, in any form, the Materials; and
(ii) authorize other persons to use, modify, reproduce, publish and
distribute, in any form, the Materials on behalf of the Ministry in order
to communicate or promote the Mission. The Delegate irrevocably waives in
favour of the Ministry all rights of integrity and other moral rights in
the Materials, for all time;
</p>
<p>
(i) Notwithstanding Section 4(h), the Materials will not infringe or induce
the infringement of any third party intellectual property rights;
</p>
<p>
(j) It will waive any rights to inspect or approve the finished media
communications and releases and will hold harmless His Majesty the King in
right of Ontario, including Her ministers, agents, appointees, and
employees (the “<strong>Indemnified Parties</strong>”), from all actions,
claims and demands arising from the Ministry’s use, modification,
reproduction, publication, and distribution of any Materials and any use or
misuse, including but not limited to, any alteration, modification, reuse
or distortion of the Materials that may occur or be caused by third
parties, including the media; and
</p>
<p>
(k) It will not use any insignia or logo of His Majesty the King in right
of Ontario unless it has received the prior written consent of the Ministry
to do so.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>5. Ministry Representations, Warranties, and Covenants.</strong>
The Delegate acknowledges that:
</p>
<p>
(a)
    For the purposes of the Mission, the Ministry will charge registration
fees to the Delegate in the amount of CAD <strong>[insert fee amount]</strong> (“<strong>Registration Fees</strong>”). The Delegate will pay the full
    amount of the Registration Fees 1 business day after the Delegate
    confirms to the Ministry receipt of the credit card payment link (also
    known as “CCPay”) sent by the Ministry. The Delegate will have 5
    business days from the date of full payment of the Registration Fees to
    cancel its participation in the Mission and to receive a full
    reimbursement of such fees. Outside of this stated time period, and at
    the sole discretion of the Ministry, the Ministry may provide the
    Delegate with a full reimbursement of its Registration Fees; and
</p>
<p>
(b) As part of the Registration Fees to be charged to the Delegate in
Section 5(a), the Ministry will provide the Delegate with the activities in
the official Mission program, including pre-Mission activities and
activities for the duration of the Mission.
<s>
</s>
</p>
<p>
<strong></strong>
</p>
<p>
<strong>6.</strong>
<strong>Insurance, Indemnity, and Waiver and Release. </strong>
The Delegate represents, warrants, and covenants that:
</p>
<p>
(a) The Delegate is responsible for its own insurance and should carry all
the necessary and appropriate insurance that a prudent person in the
business of the Delegate would maintain including but not limited to
commercial general liability insurance. The Delegate acknowledges that it
is not covered by the Government of Ontario’s insurance program and no
protection will be afforded to the Delegate<em> </em>by the Government of
Ontario for any claims that may arise out of its participation in the
Mission or any part of the Agreement;
</p>
<p>
(b) The Delegate is fully liable for any consequences of its participation
in the Mission or any part of the Agreement and the participation of the
Delegate’s employees, servants or agents (collectively, the “    <strong>Delegate Personnel</strong>”) in the Mission or any part of the
Agreement. The Delegate will indemnify and hold harmless the Indemnified
Parties from and against any (i) cause of action, liability, loss, cost,
damage, or expense (including legal, expert and consultant fees) that
anyone incurs or sustains as a result of or in connection with the Delegate
and Delegate Personnel’s participation in the Mission or any part of the
Agreement; and (ii) action, claim, demand, lawsuit, or other proceeding
that anyone makes, brings or prosecutes as a result of or in connection
with the Delegate or Delegate Personnel’s participation in the Mission or
any part of the Agreement, unless solely caused by the negligence or wilful
misconduct of the Indemnified Parties;
</p>
<p>
(c) The Delegate agrees that its liability to indemnify or reimburse the
Indemnified Parties under this Agreement will not affect or prejudice the
Indemnified Parties from exercising any other rights under law;
</p>
<p>
(d) The Delegate and Delegate Personnel and their heirs, executors and
administrators waive and release the Indemnified Parties from and against
all rights, claims, losses, etc. as identified in Section 6(b) above
whatsoever in relation to the Mission or any part of the Agreement; and
</p>
<p>
(e) The Delegate acknowledges that it has advised Delegate Personnel in
writing of the terms and conditions applicable to the Delegate Personnel
contained herein.
</p>
<p>
<strong>7. Parties Independent.</strong>
The Delegate or any Delegate Personnel are not an agent, joint venture,
partner, or employee of the Ministry, and the Delegate or any Delegate
Personnel will not represent itself in any way that might be taken by a
reasonable person to suggest that it is or take any actions that could
establish or imply such a relationship.
</p>
<p>
<strong></strong>
</p>
<p>
<strong>8. Termination on Notice and Cancellation of Mission.</strong>
The Delegate acknowledges that:
</p>
<p>
(a) The Ministry may terminate the Agreement at any time without liability,
penalty or costs upon giving notice to the Delegate;
</p>
<p>
(b) If the Ministry cancels the Mission for reasons within its control, the
Ministry will only reimburse (without interest) the Delegate’s paid
Registration Fees, as set out in Section 5(a) of the Agreement;
</p>
<p>
(c) Notwithstanding Section 8(b), if unforeseen events outside the
Ministry’s control lead to the postponement or cancellation of the Mission,
including those in relation to third party actions, the Ministry will only
reimburse (without interest) the Delegate’s paid Registration Fees, as set
out in Section 5(a) of the Agreement; and
</p>
<p>
(d) Notwithstanding Sections 8(b) and (c), the Ministry will assume no
other liability, penalty or costs.
</p>
<p>
<strong>9.</strong>
<strong>Survival.</strong>
</p>
<p>
(a) Sections 4(f), (h), (i), (j), and (k); Article 6; and Section 9(a) of
the Agreement will survive any termination, cancellation or expiry of the
Agreement; and
</p>
<p>
(b) Sections 1(a) and (c); Section 4(a); Article 7; Article 8; Section
9(b), and Article 10 of the Agreement, and any applicable definitions of
the Agreement, will continue in full force and effect for a period of 7
years from the date of termination, cancellation or expiry of the
Agreement.
</p>
<p>
<strong>10. Governing Law.</strong>
The Agreement and the rights, obligations and relations of the Parties will
be governed by and construed in accordance with the laws of the Province of
Ontario and the applicable federal laws of Canada. Any actions or
proceedings arising in connection with the Agreement or Mission will be
conducted in the courts of Ontario, which will have exclusive jurisdiction
over such proceedings.
</p>
<p>
<strong>11. Execution, Confirmation and Transmission. </strong>
The Delegate acknowledges and agrees that:<strong></strong>
<P>
(a) The Parties agree that the Agreement may be validly executed
electronically by the Delegate, and that the Delegate’s respective
electronic signature is the legal equivalent of a manual signature. The
electronic signature of the Delegate may be evidenced as follows (i) a
digital signature, including the name of the authorized signing
representative typed in the respective signature line of the Agreement or
any other means with the Ministry’s prior written consent; and (ii) the
Agreement delivered by email by the Delegate to the Ministry; and
</P>
<p>
(b) Following electronic execution by the Delegate of the Agreement, the
undersigned Delegate will send a confirmation email to the Ministry from a
verifiable email account stating that (i) it has duly executed the
Agreement; (ii) it is duly authorized to bind the Delegate with its
signature; and (iii) it will provide the date and time of the Delegate’s
signature.
</p>
<p>
<strong>
    The undersigned Delegate hereby acknowledges that it has read and
    understood the terms and conditions of the Agreement, and by providing
    its electronic signature to the Agreement, the Delegate agrees to be
    bound by all the terms and conditions contained herein.
</strong>
</p>`